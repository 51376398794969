export default {
  'en': {
    'bad credentials': 'Bad credentials!',
    'bible': 'The Bible',
    'home_redirect': 'Return to the choice of books',
    'bibletradition_redirect': 'Find out more about our research programme and the latest news',
    'chapter': 'Chapter',
    'create account': 'Create profile',
    'forgotten password': 'I forgot my password',
    'home': 'Home page',
    'ignore': 'Ignore',
    'loading': 'Loading...',
    'log in': 'Sign in',
    'log out': 'Log out',
    'login': 'Identifiers',
    'password': 'Password',
    'paying user': 'You can access paying content.',
    'make a donation': 'Donate',
    'my account': 'My profile',
    'new testament': 'New Testament',
    'next book': 'Next book',
    'no result': 'No result',
    'old testament': 'Old Testament',
    'previous book': 'Previous book',
    'psalm': 'Psalm',
    'register': 'Create your profile',
    'register text': 'Help us to continue',
    'register title': 'Do you like our work?',
    'search': 'Search',
    'search placeholder': 'Search...',
    'traditions': 'in its Traditions',
    'unavailable': "This content does not exist or is not currently available.",
    'validate': 'Validate',
    'welcome': 'Welcome',
    'your email': 'Your email',
    'your login': 'Your login:',
    'your password': 'Your password',
    'back' : 'Back',
    'chapters' : 'Chapters',
    'pericopes' : 'Pericopes',
    'about' : 'About',
    'comments' : 'Comments',
    'synthesis' : 'Synthesis',
    'no_note_selected' : 'For commentary notes, select a verse.',
    'no_notes' : 'No notes',
    'main_tradition' : 'Main tradition',
    'notes_title' : 'Notes',
    'traditions_config_title' : 'Traditions',
    'inaccessible_search' : 'Functionality reserved for registered users. <a href="#" class="open-registration-modal" title="Create a profile">Create a profile</a> to access it.',
    'active_traditions' : 'Activating traditions',
    'main_first_tradition' : 'The first will be the main',
    'impossible_remove_tradition' : 'This tradition is the default. You cannot remove it',
    'tradition_display_rule' : 'Rules for displaying traditions',
    'tradition_display_show' : 'Open all traditions',
    'tradition_display_hide' : 'Show traditions on hover',
    'select_chapter' : 'Select a chapter',
    'make_donation' : 'I make a donation',
    'login_message' : 'You can now access features reserved for registered users, such as the Bible-wide search engine and advanced display options.',
    'register_title' : 'Registration',
    'register_subtitle' : 'Why create your profile?',
    'register_content' : '<p>Registering gives you access to a wide range of options for customizing the display and consultation of our Bible, as well as a search engine.</p>\n',
    'create_account' : 'Create your profile',
    'update_account_button' : 'Update my profile',
    'profil_updated_successfully' : 'Profile successfully modified!',
    'lastname' : 'Last name',
    'name' : 'Name',
    'lastname_placeholder' : 'Your last name',
    'firstname' : 'First name',
    'firstname_placeholder' : 'Your first name',
    'email' : 'E-mail address',
    'email_placeholder' : 'Your e-mail address',
    'access': 'Access',
    'access_paying': 'Payable',
    'access_login': 'Registered',
    'access_paying_description': '<p>Your profile gives you access to all the advanced display options (choice of translations displayed), to all the notes with multimedia content, and to a search engine throughout the Bible.</p><p>By making a donation to <em>The Bible in its Traditions</em>, you will also be able to access hundreds of translations and notes in progress in your Bible.</p>',
    'access_login_description': '',
    'update_account': 'Modify your profile',
    'password_create' : 'Create your password',
    'password_placeholder' : 'At least 8 characters',
    'repeated_password' : 'Repeat password',
    'see' : 'see',
    'conditions' : 'I accept the general conditions of use',
    'unknown_modal_title': 'Content not found',
    'unknown_modal_content': 'Ouch! In the perpetual motion of our biblical cathedral\'s construction site, the' +
    'reference you\'re looking for must have been moved. Please <a href="mailto:?Subject=L’objet de mon email&amp;">let us know</a>.',
    'premium_modal_title': 'Premium content',
    'premium_modal_content': 'To preview this content, become a supporting <a href="#" class="open-donation-modal" title="Make a donation">member of The Bible in its Traditions</a>',
    'create_account_button' : 'Create profile',
      'account_donation' : 'Support our researchers',
      'account_donation_text' : '<p>The development of La Bible en ses Traditions depends largely on the generosity of its donors. If you would like to encourage us in our work, become a benefactor!</p><p>As a way of saying thank you, we will be giving you access to all the translations and notes we are working on over the coming year.</p>',
      'account_created_title' : 'Your profile has been created',
      'account_created_text' : '<p>You can now access features reserved for registered users, such as the Bible-wide search engine and advanced displayed options.</p>\n' +
          '                  <p>We\'ll keep you up to date with developments and news on the construction site of our biblical cathedral.</p>\n' +
          '                  <p>Log in now to access advanced features.</p>',
      'donation_title' :'I support the project',
      'donation_content': '<h3>Why support us?</h3>' +
          '                   <p>We want to make the priceless heritage of Scripture and culture freely available to all.\n' +
          'culture. Why graciously? Because this is our mission as Dominican friars, starting in Jerusalem:\n' +
          'to make the Word of God known and loved.</p>' +
          '                   <h3>This ambition comes at a cost</h3>' +
          '                   <p>Research work and its technical implementation require real resources.</p>' +
          '                   <h3>How will your donation be used?</h3>' +
          '                  <p>Your donation will be used to perpetuate La Bible en ses Traditions, a work of the Dominicans, by welcoming research and publishing assistants\n' +
          'research and publishing assistants at the convent in Jerusalem, and by developing and maintaining the sites and\n' +
          'and applications offered to you.</p>\n' +
          '                    <h2 class="mt-10 mb-4">Your benefits</h2>\n' +
          '                  <div class="grey-block">\n' +
          '                    <h4>Freemium access</h4>' +
          '                     <p style="font-size: 16px;" class="mb-0">for any donation of at least <strong>25 euros, you become a benefactor member</strong> of La Bible en ses Traditions for one year, and as such have the right to visit, in real time, the entire La Bible en ses Traditions project: hundreds of translations still unpublished, annotations and multimedia content under development.</p>\n' +
          '                    <h4>Tax deduction</h4>' +
          '                    <p style="font-size: 16px;" class="mb-0">We rely on the Fondation des Monastères to receive your donations.</p>' +
          '                     <p class="mt-7"><img src="/images/fondation_monastere.png" style="float: left; width: 100px; margin-right: 16px;" alt="Fondation des monastères">Located at 14 rue Brunel 75017 PARIS, the Fondation des Monastères is authorized to receive tax-deductible donations for its charitable support of members of religious and monastic communities of all Christian denominations. 5% of the amount of your donation goes to the Fondation des Monastères solidarity fund. The Fondation des Monastères systematically issues a tax receipt.</p>\n' +
          '                  </div>\n' +
          '\n' +
          '                  <p class="signature">Olivier-Thomas Venard, founder of Best</p>',
    'note_loading' : 'Note loading...',
    'tuto.button.finish' : 'Finish tutorial',
    'tuto.button.close' : 'Close',
    'tuto.button.next' : 'Next',
    'tuto.button.back' : 'Back',
    'tuto.button.other_testament' : 'Choose the other Testament',
    'tuto.welcome.title' : 'Welcome to The Bible and its Traditions',
    'tuto.welcome.content' : '<p>Here\'s how to use your bible,<br>compare translations and access all related notes.</p>.',
    'tuto.testament.content' : '<p>First of all, choose the Old or New Testament.<br><em><strong>Now it\'s your turn to click</strong></em></p>',
    'tuto.book_list.content': '<p>Then choose the book you want to consult.<br><em><strong>Click away !</strong></em></p>',
    'tuto.research.title' : 'Search and find!',
    'tuto.research.content' : '<p>You already have an idea in mind?<br>Find a word, expression or biblical reference<br><em><strong>To search, create your profile.</strong></em></p>',
    'tuto.login_register.content' : '<p>Create your profile or log in to access features reserved for registered readers.</p>',
    'tuto.introduction.title' : 'A little background',
    'tuto.introduction.content' : '<p>An introduction accompanies you as you read each book</p>',
    'tuto.chapter_pericope.content' : '<p>To access the Bible text, choose either a chapter or a particular passage (a pericope).<br><em><strong>Now it\'s up to you to click on the chapter or pericope of your choice!</strong></em></p>',
    'tuto.hover_verse.content' : '<p>The translation permanently visible is that of the 5th century Latin Bible. Discover the other versions by moving your mouse over the text.</p><hr><p>To find out more, go directly to commentaries and summaries on philology, liturgy, history, literature or culture linked to the Scriptures.<strong>Click directly on each verse to see the related notes.</strong></p>',
    'tuto.display_notes.content' : '<p>To find out more, Direct access to philological, liturgical, historical, literary and cultural commentaries and summaries relating to the Scriptures<br><br>You will gain access to hundreds of additional translations and notes by becoming a benefactor member of The Bible in its Traditions.</p>',
    'tuto.note_verse_click.content' : '<p>You can also click directly on each verse to see the notes linked to it.<br><em><strong>Now click on a verse</strong></em></p>',
    'tuto.note_click.content' : '<p>You can open a note to read it in full, take a closer look at the images or listen to the music.</p>',
    'tuto.next_chapter.title' : 'Wrong chapter?',
    'tuto.next_chapter.content' : '<p>Go directly to the next</p>',
    'tuto.tradition_config.content' : '<p>Disable certain translations or modify their display<br><em><strong>To do this, create your profile<br>(it\'s free!)</strong></em></p>',
    'tuto.click_verse_note.content' : '<p>You can also click directly on each verse to see the related notes.</p>',
    'tuto.donation.content' : '<p><strong>Do you like this bible? </strong><br>Support us so that we can continue to serve you.<br><br>By becoming a benefactor member of The Bible in its Traditions you will gain access to hundreds of previously unpublished translations, annotations and multimedia content in the process of being developed.</p>',
    'see tuto': 'Review the tutorial',
    'verses': 'versets',
    'margRefs': 'marginal references',
    'synthNotes': 'synthetic notes',
    'notes': 'notes',
    'search_note_in' : 'results in ',
    'open_google_form': '<p>The Bible in its Traditions is interactive: a comment on the translation? A suggestion for a work of art to add? Do you have a question? <br>Click here to access the form</p>',
  },
  'fr': {
    'bad credentials': 'Identifiants incorrects !',
    'bible': 'La Bible',
    'home_redirect': 'Retournez vers le choix des livres',
    'bibletradition_redirect': 'Découvrez notre programme de recherches et ses actualités',
    'chapter': 'Chapitre',
    'create account': 'Créer un profil',
    'forgotten password': 'Mot de passe oublié',
    'home': 'Accueil',
    'ignore': 'Passer',
    'loading': 'Chargement en cours...',
    'log in': 'Se connecter',
    'log out': 'Se deconnecter',
    'login': 'Identifiant',
    'password': 'Mot de passe',
    'paying user': 'Vous avez accès au contenu payant.',
    'make a donation': 'Je fais un don',
    'my account': 'Mon profil',
    'new testament': 'Nouveau Testament',
    'next book': 'Livre suivant',
    'no result': 'Aucun résultat',
    'old testament': 'Ancien Testament',
    'previous book': 'Livre précédent',
    'psalm': 'Psaume',
    'register': 'Créer son profil',
    'register text': 'Aidez-nous à continuer :',
    'register title': 'Vous appréciez notre travail ?',
    'search': 'Recherche',
    'search placeholder': 'Rechercher ...',
    'traditions': 'en ses Traditions',
    'unavailable': "Ce contenu n'existe pas ou n'est pas disponible actuellement.",
    'validate': 'Valider',
    'welcome': 'Bienvenue',
    'your email': 'Votre adresse courriel',
    'your login': 'Votre identifiant',
    'your password': 'Votre mot de passe',
    'back' : 'Retour',
    'chapters' : 'Chapitres',
    'pericopes' : 'Péricopes',
    'about' : 'À propos',
    'comments' : 'Commentaires',
    'synthesis' : 'Synthèses',
    'no_note_selected' : 'Pour avoir des notes de commentaire, selectionnez un verset.',
    'no_notes' : 'Aucune note',
    'main_tradition' : 'Tradition principale',
    'notes_title' : 'Notes',
    'traditions_config_title' : 'Traditions',
    'see': 'voir',
    'inaccessible_search' : 'Fonctionnalité réservée aux inscrits. <a href="#" class="open-registration-modal" title="Créez un compte">Créez un compte</a> pour y avoir accès.',
    'active_traditions' : 'Activer les traditions',
    'main_first_tradition' : 'La première sera la principale',
    'impossible_remove_tradition' : 'Cette tradition est la tradition par défaut. Vous ne pouvez donc pas la retirer',
    'tradition_display_rule' : 'Règles d\'affichage des traditions',
    'tradition_display_show' : 'Ouvrir toutes les traditions',
    'tradition_display_hide' : 'Afficher les traditions au survol',
    'select_chapter' : 'Sélectionnez un chapitre',
    'make_donation' : 'Je fais un don',
    'login_message' : 'Vous pouvez désormais accéder aux fonctionnalités réservées aux utilisateurs inscrits, comme le moteur de recherche dans toute la Bible et les options d\'affichage avancé.',
    'register_title' : 'Inscription',
    'register_subtitle' : 'Pourquoi créer votre profil ?',
    'register_content' : '<p>En vous inscrivant vous accédez à de nombreuses options pour personnaliser l\'affichage et la consultation de notre Bible ainsi qu\'à un moteur de recherches.</p>\n',
    'create_account' : 'Je crée mon profil',
    'update_account_button' : 'Modifier mon profil',
    'profil_updated_successfully' : 'Profil modifié avec succès !',
    'lastname' : 'Nom',
    'name': 'Nom',
    'lastname_placeholder' : 'Votre nom',
    'firstname' : 'Prénom',
    'firstname_placeholder' : 'Votre prénom',
    'email' : 'Adresse mail',
    'email_placeholder' : 'Votre adresse mail',
    'access': 'Accès',
    'access_paying': 'Donateur',
    'access_login': 'Inscrit',
    'access_paying_description': '<p>Merci de soutenir notre effort pour mettre gracieusement à disposition de tous le patrimoine inestimable de l’Écriture et de la culture !</p><p>Dès le mois suivant un don de 25 euros, vous serez pour un an membre bienfaiteur de notre programme La Bible en ses Traditions et accéderez à des centaines de traductions encore inédites et de notes en cours d\'élaboration.</p>',
    'access_login_description': '<p>Votre profil vous permet d\'accéder à toutes les options d\'affichage avancé (choix des traductions affichées), à toutes les notes présentant un contenu multimédia, et à un moteur de recherche dans toute la Bible. </p><p>En faisant un don à <em>La Bible en ses Traditions</em>, vous pourrez en outre accéder à des centaines de traductions et de notes en cours d\'élaboration dans votre bible.</p>',
    'update_account': 'Modifier votre profil',
    'password_create' : 'Créez votre mot de passe',
    'password_placeholder' : 'Au moins 8 caractères',
    'repeated_password' : 'Répétez le mot de passe',
    'conditions' : 'J\'accepte les conditions générales d\'utilisation',
    'create_account_button' : 'Créer un profil',
    'unknown_modal_title': 'Contenu non trouvé',
    'unknown_modal_content': 'Aïe ! Dans le mouvement perpétuel du chantier de notre cathédrale biblique, la\n' +
        'référence que vous cherchez a dû être déplacée. Merci de <a href="mailto:?Subject=L’objet de mon email&amp;">nous le signaler</a>.',
    'premium_modal_title': 'Contenu « Bienfaiteurs »',
    'premium_modal_content': 'Pour accéder en avant-première à ce contenu, devenez <a href="#" class="open-donation-modal" title="Faire un don">membre bienfaiteur de\n' +
        'La Bible en ses Traditions</a>',
    'account_donation' : 'Soutenez nos chercheurs',
    'account_donation_text' : '<p>Le développement de La Bible en ses Traditions dépend en grande partie de la générosité de ses donateurs. Si vous souhaitez nous encourager dans notre travail, devenez bienfaiteur !</p><p>Pour vous remercier, nous vous offrirons l’accès à l’ensemble des traductions et des notes en cours d’élaboration, durant toute l’année qui vient.</p>',
    'account_created_title' : 'Votre profil a bien été créé',
    'account_created_text' : '<p>Vous pouvez désormais accéder aux fonctionnalités réservées aux utilisateurs inscrits, comme le moteur de recherche dans toute la Bible et les options d\'affichage avancées.</p>\n' +
        '                  <p>Vous serez tenu informé des développements et des nouveautés sur le chantier de notre cathédrale biblique.</p>\n' +
        '                  <p>Connectez-vous maintenant, pour accéder aux fonctionnalités avancées.</p>',
    'donation_title' :'Je soutiens le projet',
    'donation_content': '<h3>Pourquoi nous soutenir ?</h3>' +
        '                 <p>Nous voulons mettre gracieusement à disposition de tous le patrimoine inestimable de l’Écriture et de la\n' +
        'culture. Pourquoi gracieusement ? Parce que c’est notre mission de frères dominicains, depuis Jérusalem :\n' +
        'faire connaître et aimer la Parole de Dieu.</p>' +
        '                 <h3>Cette ambition a un coût</h3>' +
        '                  <p>Le travail de recherche et sa mise en œuvre technique demandent de vrais moyens.</p>' +
        '                 <h3>À quoi votre don va-t-il servir ?</h3>' +
        '                 <p>Votre don servira à pérenniser La Bible en ses Traditions, œuvre des Dominicains, par l’accueil d’assistants\n' +
        'de recherche et d’édition au couvent de Jérusalem et par le développement et la maintenance des sites et\n' +
        'des applications qui vous sont offerts.</p>\n' +
        '                    <h2 class="mt-10 mb-4">Vos avantages</h2>' +
        '                  <div class="grey-block">\n' +
        '                     <h4>Accès « Bienfaiteurs »</h4>\n' +
        '                    <p style="font-size: 16px;" class="mb-0">pour tout don d\'au moins <strong>25 euros, vous devenez membre bienfaiteur</strong> de La Bible en ses Traditions pour une année et à ce titre avez droit de visiter, en temps réel, l\'ensemble du chantier de La Bible en ses Traditions : des centaines de traductions encore inédites, d\'annotations et de contenus multimédia en cours d\'élaboration</p>\n' +
        '                     <h4>Déduction fiscale</h4>\n' +
        '                    <p style="font-size: 16px;" class="mb-0">Nous nous appuyons sur la Fondation des Monastères pour accueillir vos dons.</p>' +
        '                     <p class="mt-7"><img src="/images/fondation_monastere.png" style="float: left; width: 100px; margin-right: 16px;" alt="Fondation des monastères">Reconnue d’utilité publique, sise au 14 rue Brunel 75017 PARIS, la Fondation est habilitée à recevoir les dons déductibles fiscalement, pour son œuvre de soutien charitable aux membres des communautés religieuses et monastiques de toutes confessions chrétiennes. 5% du montant de votre don sera versé au fonds de solidarité de la Fondation des Monastères. La Fondation des Monastères délivre systématiquement un reçu fiscal.</p>\n' +
        '                  </div>\n' +
        '\n' +
        '                  <p class="signature">Olivier-Thomas Venard, fondateur de Best</p>',
    'note_loading' : 'Chargement de la note...',
    'tuto.button.finish' : 'Terminer le tutoriel',
    'tuto.button.close' : 'Fermer',
    'tuto.button.next' : 'Suivant',
    'tuto.button.back' : 'Retour',
    'tuto.button.other_testament' : 'Choisir l\'autre Testament',
    'tuto.welcome.title' : 'Bienvenue dans La bible en ses Traditions',
    'tuto.welcome.content' : '<p>Voici comment utiliser votre bible,<br>comparer les traductions et accéder à toutes les notes associées</p>',
    'tuto.testament.content' : '<p>Tout d’abord, choisissez l’Ancien ou le Nouveau Testament.<br><em><strong>C’est à vous de cliquer !</strong></em></p>',
    'tuto.book_list.content' : '<p>Choisissez ensuite le livre que vous voulez consulter.<br><em><strong>À vous de cliquer !</strong></em></p>',
    'tuto.research.title' : 'Qui recherche trouve !',
    'tuto.research.content' : '<p>Vous avez déjà une idée en tête ?<br>Trouvez un mot, une expression ou une référence biblique<br><em><strong>Pour faire une recherche, créez votre profil.</strong></em></p>',
    'tuto.login_register.content' : '<p>Créez votre profil ou identifiez-vous pour accéder aux fonctionnalités réservées aux lecteurs inscrits.</p>',
    'tuto.introduction.title' : 'Un peu de contexte',
    'tuto.introduction.content' : '<p>Une introduction vous accompagne dans la lecture de chaque livre</p>',
    'tuto.chapter_pericope.content' : '<p>Pour accéder au texte biblique, choisissez soit un chapitre, soit un passage en particulier (une péricope)<br><em><strong>A vous de cliquer maintenant sur le chapitre ou la péricope de votre choix !</strong></em></p>',
    'tuto.hover_verse.content' : '<p>La traduction visible en permanence est celle de la bible latine du  Ve siècle. Découvrez les autres versions en passant votre souris sur le texte.</p><hr><p>Pour aller plus loin, accédez directement à des commentaires et des synthèses philologiques, liturgiques, historiques, littéraires ou culturelles liées aux Écritures. <strong>Cliquez directement sur chaque verset pour voir les notes qui y sont liées.</strong></p>',
    'tuto.display_notes.content' : '<p>Pour aller plus loin, accédez directement à des commentaires et des synthèses philologiques, liturgiques, historiques, littéraires ou culturelles liées aux Écritures<br><br>Vous accéderez à des centaines de traductions et de notes supplémentaires, en devenant membre bienfaiteur de La Bible en ses Traditions.</p>',
    'tuto.note_verse_click.content' : '<p>Vous pouvez aussi cliquer directement sur chaque verset pour voir les notes qui y sont liées.<br><em><strong>Cliquez maintenant sur un verset</strong></em></p>',
    'tuto.note_click.content' : '<p>Vous pouvez ouvrir une note pour la lire entièrement, regarder de plus près les images ou écouter la musique.</p>',
    'tuto.next_chapter.title' : 'Pas le bon chapitre ?',
    'tuto.next_chapter.content' : '<p>Passez directement au suivant</p>',
    'tuto.tradition_config.content' : '<p>Désactivez certaines traductions ou modifiez leur affichage<br><em><strong>Pour pouvoir le faire, créez votre profil<br>(c’est gratuit !)</strong></em></p>',
    'tuto.click_verse_note.content' : '<p>Vous pouvez aussi <strong>cliquer directement sur chaque verset</strong> pour voir les notes qui y sont liées</p>',
    'tuto.donation.content' : '<p><strong>Vous aimez cette bible ?</strong> <br>Soutenez-nous pour que nous puissions continuer notre travail à votre service.<br><br>En devenant membre bienfaiteur de La Bible en ses Traditions vous accéderez à des des centaines de traductions encore inédites, d\'annotations et de contenus multimédia en cours d\'élaboration</p>',
    'see tuto': 'Revoir le tutoriel',
    'verses': 'les versets',
    'margRefs': 'les références marginales',
    'synthNotes': 'les notes de synthèses',
    'notes': 'les notes',
    'search_note_in' : 'résultats dans ',
    'open_google_form': '<p>La Bible en ses Traditions est interactive : une remarque sur la traduction? Une suggestion d’œuvre d’art à ajouter ? Une question ?<br>Cliquez ici pour accéder au formulaire</p>',
  },
};