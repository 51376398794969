var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "home" }, [
    _c("div", { staticClass: "top-banner" }, [
      _c("div", { staticClass: "container py-0" }, [
        _c(
          "ul",
          { ref: "tuto-highlight-nav-testament", staticClass: "nav_testament" },
          _vm._l(this.testaments, function (testament, index) {
            return _c(
              "li",
              {
                class:
                  testament.indexStart === _vm.currentTestament.indexStart
                    ? "active"
                    : "inactive",
                on: {
                  click: function ($event) {
                    return _vm.updateCurrentTestament(index)
                  },
                },
              },
              [_vm._v(_vm._s(testament.name) + "\n        ")]
            )
          }),
          0
        ),
      ]),
    ]),
    _vm._v(" "),
    !_vm.isLoading
      ? _c(
          "div",
          { staticClass: "container pt-18" },
          _vm._l(this.groupingBooksTestament, function (grouping, index) {
            return _c("div", { staticClass: "mb-8" }, [
              _c("p", { staticClass: "title-2-merriweather mb-6" }, [
                _vm._v(_vm._s(_vm.getTestamentGrouping(index)[_vm.locale])),
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "d-flex flex-wrap",
                  class: index === 0 ? "tuto-highlight-nav-books" : "",
                },
                _vm._l(grouping, function (book) {
                  return _c(
                    "router-link",
                    {
                      staticClass: "book_description",
                      style:
                        "--color-underline-book:" +
                        _vm.getColorBook(book.url_param),
                      attrs: {
                        to: {
                          name: "book",
                          params: { bookAbbreviation: book.url_param },
                        },
                      },
                      nativeOn: {
                        click: function ($event) {
                          return _vm.nextPageClick()
                        },
                      },
                    },
                    [
                      _c("div", [
                        _c("p", { staticClass: "titre" }, [
                          _vm._v(_vm._s(book.name)),
                        ]),
                        _vm._v(" "),
                        _vm.getSyntheticNote(book.abbreviation)
                          ? _c("p", [
                              _vm._v(
                                _vm._s(
                                  _vm.getSyntheticNote(book.abbreviation).text
                                )
                              ),
                            ])
                          : _vm._e(),
                      ]),
                    ]
                  )
                }),
                1
              ),
            ])
          }),
          0
        )
      : _c("p", [_vm._v("\n    " + _vm._s(_vm.$t("loading")) + "\n  ")]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }