export default class NoteTypeMatcher {
    getNoteType(registers, rubric) {
        for (const [typeAbbr, subTypes] of Object.entries(registers)) {
            for (const [abbrSubType, nameType] of Object.entries(subTypes)) {
                if(abbrSubType === rubric) {
                    return nameType
                }
            }
        }
        return rubric;
    }
}