import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

const defaultLocale = 'fr';
const locales = ['en', 'fr'];
const editions = {
  free: {
    en: 'best_en',
    fr: 'best_fr',
  },
  paying: {
    en: 'best_en',
    fr: 'best_fr',
  },
};
const testamentsGrouping = {
    0: [{fr : 'Pentateuque', en : 'Pentateuch'}, {fr : 'Livres historiques', en : 'Historical books'}, {fr : 'Livres historiques', en : 'Historical books'}, {fr : 'Livres historiques', en : 'Historical books'}, {fr : 'Livres historiques', en : 'Historical books'}, {fr : 'Livres de sagesse', en : 'Wisdom books'}, {fr : 'Livres de sagesse', en : 'Wisdom books'}, {fr : 'Prophètes majeurs', en : 'Major Prophets'}, {fr : 'Prophètes mineurs', en : 'Minor Prophets'}],
    1: [{fr : 'Évangiles', en : 'Gospels'}, {fr : 'Actes des apôtres', en : 'Acts of the Apostles'}, {fr : 'Épîtres pauliniennes', en : 'Pauline epistles'}, {fr : 'Épîtres pastorales', en : 'Pastoral epistles'}, {fr : 'Épîtres catholiques', en : 'Catholic epistles'},{fr : 'Apocalypse', en : 'Apocalypse'}],
};
const bookColors = [
    {color: '#50998c', books: ['Gn', 'Ex', 'Lv', 'Nb', 'Dt', 'Mt', 'Mk', 'Lk', 'Jn']},
    {color: '#427c89', books: ['Jos', 'Jg', 'Rt', '1S', '2S', '1K', '2K', '1Ch', '2Ch', 'Ezr', 'Ne', 'Est', 'Ac']},
    {color: '#ddbb7f', books: ['Tb', 'Jdt', '1M', '2M', 'Ws', 'Si', 'Ba']},
    {color: '#817baa', books: ['Jb', 'Ps', 'Pr', 'Qo', 'Sg', 'Lm', 'Rm', '1Co', '2Co', 'Ga', 'Ep', 'Ph', 'Col', '1Th', '2Th', '1Tm', '2Tm', 'Tt', 'Phm']},
    {color: '#ce5b90', books: ['Is', 'Jr', 'Ezk', 'Dn', 'Heb', 'Jm', '1P', '2P', '1Jn', '2Jn', '3Jn', 'Jd']},
    {color: '#a5a5a5', books: ['Ho', 'Jl', 'Am', 'Ob', 'Jon', 'Mi', 'Na', 'Hab', 'Zp', 'Hg', 'Zc', 'Ml', 'Rv']},
];
const traditions= {
    default: {name: {fr: '', en: ''}, fullLabel: {fr: '', en: ''}, labelPanelConfiguration: {fr: '', en: ''}, color: '#004EDE'},
    G: {name: {fr: 'Septante', en: 'Septante'}, fullLabel: {fr: 'Septante', en: 'Septante'}, labelPanelConfiguration: {fr: 'Grec (Septante)', en: 'Greek (Septante)'}, color: '#004EDE', isDefault : false},
    M: {name: {fr: 'Massorétique', en: 'Massoretics'}, fullLabel: {fr: 'Texte massorétique', en: 'Massoretic text'}, labelPanelConfiguration: {fr: 'Hébreu (Texte massorétique)', en: 'Hebrew (Masoretic text)'}, color: '#004EDE', isDefault : false},
    S: {name: {fr: 'Peshitta', en: 'Peshitta'}, fullLabel: {fr: 'Peshitta', en: 'Peshitta'}, labelPanelConfiguration: {fr: 'Syriaque (Peshitta)', en: 'Syriac (Peshitta)'}, color: '#004EDE', isDefault : false},
    Sam: {name: {fr: 'Pentateuque samaritain', en: 'Samaritan Pentateuch'}, fullLabel: {fr: 'Pentateuque samaritain', en: 'Samaritan Pentateuch'}, labelPanelConfiguration: {fr: 'Samaritain (Pentateuque samaritain)', en: 'Samaritan (Samaritan Pentateuch)'}, color: '#004EDE', isDefault : false},
    SJ: {name: {fr: 'Josué samaritain', en: 'Joshua the Samaritan'}, fullLabel: {fr: 'Josué samaritain', en: 'Joshua the Samaritan'}, labelPanelConfiguration: {fr: 'Samaritain (Josué samaritain)', en: 'Samaritan (Joshua Samaritan)'}, color: '#004EDE', isDefault : false},
    V: {name: {fr: 'Vulgate', en: 'Vulgate'}, fullLabel: {fr: 'Vulgate', en: 'Vulgate'}, labelPanelConfiguration: {fr: 'Latin (Vulgate)', en: 'Latin (Vulgate)'}, color: '#004EDE', isDefault : true},
    Byz: {name: {fr: 'Byz', en: 'Byz'}, fullLabel: {fr: 'Texte byzantin', en: 'Byzantine text'}, labelPanelConfiguration: {fr: 'Grec (Texte byzantin)', en: 'Greek (Byzantine text)'}, color: '#004EDE'},
    TR: {name: {fr: 'TR', en: 'TR'}, fullLabel: {fr: 'Textus Receptus', en: 'Textus Receptus'}, labelPanelConfiguration: {fr: 'Grec (Textus Receptus)', en: 'Greek (Textus Receptus)'}, color: '#004EDE'},
    Nes: {name: {fr: 'Nes', en: 'Nes'}, fullLabel: {fr: 'Nestle-Aland', en: 'Nestle-Aland'}, labelPanelConfiguration: {fr: 'Grec (Nestle-Aland)', en: 'Greek (Nestle-Aland)'}, color: '#004EDE'},
    D: {name: {fr: 'D', en: 'D'}, fullLabel: {fr: 'Codex de Bèze', en: 'Codex de Bèze'}, labelPanelConfiguration: {fr: 'Codex de Bèze', en: 'Codex de Bèze'}, color: '#004EDE'},
};
const registers= {
    text: {label : {fr: 'Texte', en: 'Text'}, color: '#EEA82D'},
    context: {label : {fr: 'Contexte', en: 'Context'}, color: '#349C72'},
    reception: {label : {fr: 'Réception', en: 'Reception'}, color: '#004EDE'},
};

export default new Vuex.Store({
  actions: {
    loadFromLocalStorage: ({ commit, getters }) => {
      const edition = getters.getBibleEdition();
      const rubrics = localStorage.getItem('rubrics_' + edition);
      if (rubrics && rubrics.edition) {
          commit('SET_RUBRICS', JSON.parse(rubrics));
      }
      const toc = localStorage.getItem('toc_' + edition);
      if (toc && toc.edition) {
          commit('SET_TOC', JSON.parse(toc));
      }
      const token = localStorage.getItem('token');
      if (token) {
        let base64Url = token.split('.')[1];
        let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        let jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));

        let parsedPayload = JSON.parse(jsonPayload);
        let nowTimestamp =  Math.floor(Date.now() / 1000)

        if(parsedPayload.exp >= nowTimestamp) {
          commit('SET_TOKEN', token);
        }
      }
      const token_api = localStorage.getItem('token_api');
      if (token_api) {
          commit('SET_TOKEN_API', token_api); // FIXME auto-refetch token if nearly expired and auto-clear if expired
      }
      const locale = localStorage.getItem('locale', defaultLocale);
      commit('SET_LOCALE', locales.includes(locale) ? locale : defaultLocale);
    },
    resetActiveTraditions: ({ commit }) => {
      commit('SET_ACTIVE_TRADITIONS', ['V']);
    }
  },
  mutations: {
    SET_ACTIVE_TRADITIONS (state, payload) {
        state.activesTraditions = payload;
    },
    SET_MARG_REFS_VERSE (state, payload) {
        state.margRefs = Object.assign(state.margRefs, payload);
    },
    SET_NOTES_VERSE (state, payload) {
        state.notes = Object.assign(state.notes, payload);
    },
    SET_ACTIVE (state, payload) {
      state.mainTradition = payload;
      window.$vueEventBus.$emit('tradition_change');
    },
    SET_ACTIVE_VERSE (state, payload) {
      state.activeVerse = payload;
    },
    SET_BIBLE_API_URL (state, payload) {
      state.bibleApiUrl = payload;
    },
    SET_MEDIA_AUTHORIZATION (state, payload) {
      state.mediaAuthorization = payload;
    },
    SET_MEDIA_PLATFORM_URL (state, payload) {
      state.mediaPlatformUrl = payload;
    },
    SET_BOOK_INFORMATIONS (state, payload) {
      if (!state[payload.edition]) {
        state[payload.edition] = {};
      }
      if (!state[payload.edition].booksInfo) {
        state[payload.edition].booksInfo = {};
      }
      state[payload.edition].booksInfo[payload.id] = payload.content;
    },
    SET_BOOK (state, payload) {
      if (!state[payload.edition]) {
        state[payload.edition] = {};
      }
      if (!state[payload.edition].books) {
        state[payload.edition].books = {};
      }
      state[payload.edition].books[payload.id] = payload.content; // NB: too big for localStorage
    },
    SET_LOCALE (state, payload) {
      if (locales.includes(payload) && payload != state.locale) {
        state.locale = payload;
        localStorage.setItem('locale', state.locale);
        window.$vueEventBus.$emit('locale_change');
      }
    },
    SET_RECAPTCHA_SITE_KEY (state, payload) {
      state.recaptchaSiteKey = payload;
    },
    SET_RUBRICS (state, payload) {
      if (!state[payload.edition]) {
        state[payload.edition] = {};
      }
      state[payload.edition].rubrics = payload;
      if (payload.save) {
        localStorage.setItem('rubrics_' + payload.edition, JSON.stringify(payload));
      }
    },
    SET_SYNTH_NOTES (state, payload) {
      if (!state[payload.edition]) {
        state[payload.edition] = {};
      }
      state[payload.edition].synthNotes = payload; // NB: too big for localStorage
    },
    SET_INTRODUCTIONS (state, payload) {
      if (!state[payload.edition]) {
        state[payload.edition] = {};
      }
      state[payload.edition].introductions = payload; // NB: too big for localStorage
    },
    SET_TOC (state, payload) {
      if (!state[payload.edition]) {
        state[payload.edition] = {};
      }
      state[payload.edition].toc = payload;
      if (payload.save) {
        localStorage.setItem('toc_' + payload.edition, JSON.stringify(payload));
      }
    },
    SET_TOKEN (state, payload) {
      state.token = payload;
      localStorage.setItem('token', payload);
    },
    SET_API_TOKEN (state, payload) {
      state.apiToken = payload;
      localStorage.setItem('api_token', payload);
      window.$vueEventBus.$emit('user_change');
    },
    SET_USER (state, payload) {
      state.user = payload;
    },
    SET_TRADUCTION_CONFIGURATION (state, payload) {
      state.traductionConfiguration = payload;
    },
  },
  // NB: an introduction is a synthetic note with a non-null scope.
  getters: {
    _state:                    (state, getters) => () => state[getters.getBibleEdition()] || {},
    isPaying:                  (state, getters) => () => state.user && state.user.roles && state.user.roles.includes('ROLE_PAYING'),
    getMargRefsByVerse:         (state) => (verseId) => state.margRefs ? state.margRefs[verseId]: [],
    getNotesByVerse:            (state) => (verseId) => state.notes ? state.notes[verseId] : [],
    getActivesTraditions:     (state, getters) => () => state.user && getters.isPaying && state.user.active_traditions && state.user.active_traditions.length ? state.user.active_traditions : state.activesTraditions,
    getAllTraditions:          (state) => () => traditions,
    getTraductionConfiguration: (state,getters) => () => state.user && getters.isPaying && state.user.traductions_display_mode ? state.user.traductions_display_mode : state.traductionConfiguration,
    getActiveVerse:            (state) => () => state.activeVerse,
    getTestamentsGrouping:     (state) => () => testamentsGrouping,
    getBook:                   (state, getters) => (book) => (getters._state().books || {})[book] || null,
    getColorBook:               (state, getters) => (abbr) => bookColors.find((color) => color.books.includes(abbr)),
    getBookInformationsByAbbreviation:     (state, getters) => (abbr) => (getters._state().booksInfo ? getters._state() :  {booksInfo: []}).booksInfo[abbr],
    getBookByAbbreviation:     (state, getters) => (abbr) => (getters._state().toc || { books: []}).books.find(book => book.abbreviation === abbr),
    getBookByUrlParam:         (state, getters) => (param) => (getters._state().toc || { books: []}).books.find(book => book.url_param === param),
    getBibleApiUrl:            (state) => () => state.bibleApiUrl,
    getMediaAuthorization:     (state, getters) => () => state.mediaAuthorization,
    getMediaPlatformUrl:       (state) => () => state.mediaPlatformUrl,
    getBibleEdition:           (state, getters) => () => editions[getters.isPaying() ? 'paying' : 'free'][getters.getLocale()],
    getIntroduction:           (state, getters) => (id) => getters._state().introductions.find(note => note.id === id),
    getIntroductionByUrlParam: (state, getters) => (param) => getters._state().introductions.find(note => note.scope.url_param.startsWith(param)),
    getIntroductions:          (state, getters) => () => getters._state().introductions,
    getLocale:                 (state) => () => state.locale,
    getRecaptchaSiteKey:       (state) => () => state.recaptchaSiteKey,
    getRubrics:                (state, getters) => () => getters._state().rubrics,
    getSynthNote:              (state, getters) => (id) => getters._state().synthNotes.find(note => note.scope === null && note.id === id),
    getSynthNotes:             (state, getters) => () => getters._state().synthNotes.filter(note => note.scope === null),
    getToc:                    (state, getters) => () => getters._state().toc,
    getToken:                  (state) => () => state.token,
    getRegistersNames:       (state) => ()  => registers,
    getUser:                    (state) => () => state.user,
    isSynthNotesLoaded:        (state, getters) => () => getters._state().synthNotes && getters._state().synthNotes.length !== 0,
    isIntroductionsLoaded:     (state, getters) => () => getters._state().introductions && getters._state().introductions.length !== 0,
  },
  state: {
    rubrics: {},
    margRefs: {},
    notes: {},
    activeVerse: null,
    mainTradition : 'V',
    activesTraditions: ['V'],
    traductionConfiguration: 'close',
    bibleApiUrl: '',
    mediaAuthorization: {},
    mediaPlatformUrl: '',
    books: {},
    booksInfo: [],
    testament: null,
    locale: defaultLocale,
    recaptchaSiteKey: '',
    synthNotes: [],
    introductions: [],
    toc: null,
    user: null,
    apiToken: null,
  },
});
