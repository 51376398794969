var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-menu",
    {
      attrs: { "close-on-content-click": false, "offset-x": "" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function (ref) {
            var on = ref.on
            var attrs = ref.attrs
            return [
              _c(
                "v-btn",
                _vm._g(
                  _vm._b(
                    { staticClass: "login-btn btn-red mr-3" },
                    "v-btn",
                    attrs,
                    false
                  ),
                  on
                ),
                [
                  _vm._v(
                    _vm._s(_vm.token ? _vm.$t("my account") : _vm.$t("log in"))
                  ),
                ]
              ),
            ]
          },
        },
      ]),
      model: {
        value: _vm.menu,
        callback: function ($$v) {
          _vm.menu = $$v
        },
        expression: "menu",
      },
    },
    [
      _vm._v(" "),
      [
        _c(
          "v-tabs",
          { staticClass: "elevation-2 panel-login" },
          [
            _c("v-tabs-slider"),
            _vm._v(" "),
            !_vm.token
              ? _c(
                  "v-tab",
                  { staticClass: "tab-element", attrs: { href: "#tab-login" } },
                  [_vm._v("\n        " + _vm._s(_vm.$t("log in")) + "\n      ")]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.token
              ? _c(
                  "v-tab",
                  {
                    staticClass: "tab-element",
                    attrs: { href: "#tab-account" },
                  },
                  [
                    _vm._v(
                      "\n        " + _vm._s(_vm.$t("my account")) + "\n      "
                    ),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            !_vm.token
              ? _c(
                  "v-tab-item",
                  { attrs: { value: "tab-login" } },
                  [
                    _c(
                      "v-card",
                      { attrs: { flat: "", tile: "" } },
                      [
                        _c(
                          "v-card-text",
                          { staticClass: "login-form-wrapper" },
                          [
                            _c(
                              "div",
                              { staticClass: "close-modal close-login-modal" },
                              [
                                _c(
                                  "svg",
                                  {
                                    staticClass:
                                      "icon icon-tabler icon-tabler-x",
                                    attrs: {
                                      xmlns: "http://www.w3.org/2000/svg",
                                      width: "28",
                                      height: "28",
                                      viewBox: "0 0 24 24",
                                      "stroke-width": "1.5",
                                      stroke: "#000000",
                                      fill: "none",
                                      "stroke-linecap": "round",
                                      "stroke-linejoin": "round",
                                    },
                                  },
                                  [
                                    _c("path", {
                                      attrs: {
                                        stroke: "none",
                                        d: "M0 0h24v24H0z",
                                        fill: "none",
                                      },
                                    }),
                                    _c("path", {
                                      attrs: { d: "M18 6l-12 12" },
                                    }),
                                    _c("path", { attrs: { d: "M6 6l12 12" } }),
                                  ]
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("h1", [_vm._v("Connexion")]),
                            _vm._v(" "),
                            _c("div", { staticClass: "form-group" }, [
                              _c("label", { attrs: { for: "login" } }, [
                                _vm._v(_vm._s(_vm.$t("login"))),
                              ]),
                              _vm._v(" "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.username,
                                    expression: "username",
                                  },
                                ],
                                staticClass: "form-control",
                                attrs: {
                                  id: "login",
                                  name: "_username",
                                  placeholder: _vm.$t("your email"),
                                },
                                domProps: { value: _vm.username },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.username = $event.target.value
                                  },
                                },
                              }),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "form-group" }, [
                              _c("label", { attrs: { for: "password" } }, [
                                _vm._v(_vm._s(_vm.$t("password"))),
                              ]),
                              _vm._v(" "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.password,
                                    expression: "password",
                                  },
                                ],
                                staticClass: "form-control",
                                attrs: {
                                  id: "password",
                                  name: "_password",
                                  placeholder: _vm.$t("your password"),
                                  type: "password",
                                },
                                domProps: { value: _vm.password },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.password = $event.target.value
                                  },
                                },
                              }),
                            ]),
                            _vm._v(" "),
                            _vm.error
                              ? _c("p", { staticClass: "text-red" }, [
                                  _vm._v(_vm._s(_vm.error)),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "a",
                              {
                                staticClass: "btn-red mr-3",
                                attrs: {
                                  href: _vm.Routing.generate(
                                    "front_security_forgot_password"
                                  ),
                                  target: "_blank",
                                },
                              },
                              [_vm._v(_vm._s(_vm.$t("forgotten password")))]
                            ),
                            _vm._v(" "),
                            _c(
                              "button",
                              {
                                staticClass: "btn-red full",
                                on: { click: _vm.login },
                              },
                              [_vm._v(_vm._s(_vm.$t("log in")))]
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.token
              ? _c(
                  "v-tab-item",
                  { attrs: { value: "tab-account" } },
                  [
                    !_vm.show_update_account
                      ? _c(
                          "v-card",
                          { attrs: { flat: "", tile: "" } },
                          [
                            _c("v-card-text", [
                              _c(
                                "div",
                                {
                                  staticClass: "close-modal close-login-modal",
                                },
                                [
                                  _c(
                                    "svg",
                                    {
                                      staticClass:
                                        "icon icon-tabler icon-tabler-x",
                                      attrs: {
                                        xmlns: "http://www.w3.org/2000/svg",
                                        width: "28",
                                        height: "28",
                                        viewBox: "0 0 24 24",
                                        "stroke-width": "1.5",
                                        stroke: "#000000",
                                        fill: "none",
                                        "stroke-linecap": "round",
                                        "stroke-linejoin": "round",
                                      },
                                    },
                                    [
                                      _c("path", {
                                        attrs: {
                                          stroke: "none",
                                          d: "M0 0h24v24H0z",
                                          fill: "none",
                                        },
                                      }),
                                      _c("path", {
                                        attrs: { d: "M18 6l-12 12" },
                                      }),
                                      _c("path", {
                                        attrs: { d: "M6 6l12 12" },
                                      }),
                                    ]
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "registration-welcome" },
                                [
                                  _c("div", [
                                    _c("h2", [
                                      _vm._v(_vm._s(_vm.$t("my account"))),
                                    ]),
                                    _vm._v(" "),
                                    _c("p", [
                                      _c("strong", [
                                        _vm._v(_vm._s(_vm.$t("name"))),
                                      ]),
                                      _vm._v(" : " + _vm._s(_vm.user.fullname)),
                                    ]),
                                    _vm._v(" "),
                                    _c("p", [
                                      _c("strong", [
                                        _vm._v(_vm._s(_vm.$t("email"))),
                                      ]),
                                      _vm._v(" : " + _vm._s(_vm.user.email)),
                                    ]),
                                    _vm._v(" "),
                                    _c("p", [
                                      _c("strong", [
                                        _vm._v(_vm._s(_vm.$t("access"))),
                                      ]),
                                      _vm._v(
                                        " : " +
                                          _vm._s(
                                            _vm.user.roles &&
                                              _vm.user.roles.includes(
                                                "ROLE_PAYING"
                                              )
                                              ? _vm.$t("access_paying")
                                              : _vm.$t("access_login")
                                          )
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c("div", {
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.user.roles &&
                                            _vm.user.roles.includes(
                                              "ROLE_PAYING"
                                            )
                                            ? _vm.$t(
                                                "access_paying_description"
                                              )
                                            : _vm.$t("access_login_description")
                                        ),
                                      },
                                    }),
                                  ]),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "buttons" }, [
                                    _c(
                                      "button",
                                      {
                                        staticClass: "btn-red",
                                        on: { click: _vm.logout },
                                      },
                                      [_vm._v(_vm._s(_vm.$t("log out")))]
                                    ),
                                    _vm._v(" "),
                                    _vm.user
                                      ? _c(
                                          "button",
                                          {
                                            staticClass: "btn-red",
                                            on: {
                                              click: function ($event) {
                                                return _vm.showUpdateAccount(
                                                  true
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(_vm.$t("update_account"))
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c(
                                      "a",
                                      {
                                        staticClass: "btn-red full",
                                        attrs: {
                                          href: "https://don.fondationdesmonasteres.org/soutenir-Bible-en-ses-Traditions",
                                          target: "_blank",
                                        },
                                      },
                                      [_vm._v(_vm._s(_vm.$t("make_donation")))]
                                    ),
                                  ]),
                                ]
                              ),
                            ]),
                          ],
                          1
                        )
                      : _vm.user
                      ? _c(
                          "v-card",
                          { attrs: { flat: "", tile: "" } },
                          [
                            _c(
                              "v-card-text",
                              [
                                _c("UpdateAccount", {
                                  attrs: { user: _vm.user },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                )
              : _vm._e(),
          ],
          1
        ),
      ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }