var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-app-bar", { attrs: { app: "" } }, [
    _c(
      "div",
      {
        staticClass: "d-flex justify-content-between align-items-center w-100",
      },
      [
        _c(
          "div",
          { staticClass: "d-flex justify-content-between align-items-center" },
          [
            _c(
              "div",
              { staticClass: "mr-4" },
              [
                _c(
                  "v-app-bar-nav-icon",
                  [
                    _c("router-link", { attrs: { to: { name: "home" } } }, [
                      _c("img", {
                        staticClass: "logo light-theme-item",
                        attrs: {
                          src: "/images/logo-black.png",
                          title: _vm.$t("home_redirect"),
                          "data-toggle": "tooltip",
                          "data-placement": "bottom",
                          alt: "Logo",
                        },
                      }),
                      _vm._v(" "),
                      _c("img", {
                        staticClass: "logo dark-theme-item",
                        attrs: {
                          src: "/images/logo-white.png",
                          title: _vm.$t("home_redirect"),
                          "data-toggle": "tooltip",
                          "data-placement": "bottom",
                          alt: "Logo",
                        },
                      }),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c("v-toolbar-title", [
              _c(
                "a",
                {
                  staticClass: "main-navigation-router",
                  attrs: {
                    href: "https://www.bibletraditions.org/",
                    title: _vm.$t("bibletradition_redirect"),
                    "data-toggle": "tooltip",
                    "data-placement": "bottom",
                  },
                },
                [
                  _c("span", { staticClass: "bible" }, [
                    _vm._v(_vm._s(_vm.$t("bible"))),
                  ]),
                  _c("br"),
                  _vm._v(_vm._s(_vm.$t("traditions")) + "\n        "),
                ]
              ),
            ]),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "d-flex justify-content-between align-items-center" },
          [
            _c(
              "div",
              {
                staticClass: "search mr-6",
                class: [_vm.token ? "" : "search-no-access"],
              },
              [_c("Search", { attrs: { user: _vm.user, token: _vm.token } })],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "open-mobile-menu" }, [
              _c(
                "svg",
                {
                  staticClass: "icon icon-tabler icon-tabler-menu-deep",
                  attrs: {
                    xmlns: "http://www.w3.org/2000/svg",
                    width: "28",
                    height: "28",
                    viewBox: "0 0 24 24",
                    "stroke-width": "1.5",
                    stroke: "#C91B23",
                    fill: "none",
                    "stroke-linecap": "round",
                    "stroke-linejoin": "round",
                  },
                },
                [
                  _c("path", {
                    attrs: { stroke: "none", d: "M0 0h24v24H0z", fill: "none" },
                  }),
                  _vm._v(" "),
                  _c("path", { attrs: { d: "M4 6h16" } }),
                  _vm._v(" "),
                  _c("path", { attrs: { d: "M7 12h13" } }),
                  _vm._v(" "),
                  _c("path", { attrs: { d: "M10 18h10" } }),
                ]
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "custom-mobile-menu" }, [
              _c("div", { staticClass: "locale-switcher mr-4" }, [
                _c(
                  "svg",
                  {
                    staticClass: "chevron",
                    attrs: {
                      xmlns: "http://www.w3.org/2000/svg",
                      width: "24",
                      height: "24",
                      viewBox: "0 0 24 24",
                      "stroke-width": "1.5",
                      stroke: "#1B2847",
                      fill: "none",
                      "stroke-linecap": "round",
                      "stroke-linejoin": "round",
                    },
                  },
                  [
                    _c("path", {
                      attrs: {
                        stroke: "none",
                        d: "M0 0h24v24H0z",
                        fill: "none",
                      },
                    }),
                    _c("path", { attrs: { d: "M6 9l6 6l6 -6" } }),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "select",
                  {
                    on: {
                      change: function ($event) {
                        return _vm.changeLocale($event)
                      },
                    },
                  },
                  [
                    _c(
                      "option",
                      {
                        attrs: { value: "fr" },
                        domProps: {
                          selected: _vm.$i18n.locale == "fr" ? "selected" : "",
                        },
                      },
                      [_vm._v("Fr.")]
                    ),
                    _vm._v(" "),
                    _c(
                      "option",
                      {
                        attrs: { value: "en" },
                        domProps: {
                          selected: _vm.$i18n.locale == "en" ? "selected" : "",
                        },
                      },
                      [_vm._v("En.")]
                    ),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "tuto-highlight-login d-flex" },
                [
                  _c("Login", { attrs: { user: _vm.user, token: _vm.token } }),
                  _vm._v(" "),
                  !_vm.token
                    ? _c(
                        "div",
                        [
                          _c("Registration", {
                            attrs: { user: _vm.user, token: _vm.token },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                [
                  _c(
                    "v-menu",
                    {
                      attrs: {
                        "close-on-content-click": false,
                        "offset-x": "",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function (ref) {
                            var on = ref.on
                            var attrs = ref.attrs
                            return [
                              _c(
                                "v-btn",
                                _vm._g(
                                  _vm._b(
                                    {
                                      staticClass:
                                        "donation-btn btn-red full mr-3 tuto-highlight-donation",
                                      staticStyle: {
                                        padding: "13px 16px !important",
                                      },
                                      attrs: {
                                        title: _vm.$t("make a donation"),
                                        "data-toggle": "tooltip",
                                        "data-placement": "bottom",
                                      },
                                    },
                                    "v-btn",
                                    attrs,
                                    false
                                  ),
                                  on
                                ),
                                [
                                  _c(
                                    "svg",
                                    {
                                      staticClass:
                                        "icon icon-tabler icon-tabler-heart",
                                      attrs: {
                                        xmlns: "http://www.w3.org/2000/svg",
                                        width: "24",
                                        height: "24",
                                        viewBox: "0 0 24 24",
                                        "stroke-width": "1.5",
                                        stroke: "#fff",
                                        fill: "none",
                                        "stroke-linecap": "round",
                                        "stroke-linejoin": "round",
                                      },
                                    },
                                    [
                                      _c("path", {
                                        attrs: {
                                          stroke: "none",
                                          d: "M0 0h24v24H0z",
                                          fill: "none",
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("path", {
                                        attrs: {
                                          d: "M19.5 12.572l-7.5 7.428l-7.5 -7.428a5 5 0 1 1 7.5 -6.566a5 5 0 1 1 7.5 6.572",
                                        },
                                      }),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          },
                        },
                      ]),
                      model: {
                        value: _vm.donationMenu,
                        callback: function ($$v) {
                          _vm.donationMenu = $$v
                        },
                        expression: "donationMenu",
                      },
                    },
                    [
                      _vm._v(" "),
                      [
                        _c(
                          "v-tabs",
                          {
                            staticClass:
                              "elevation-2 panel-register modal-donation",
                          },
                          [
                            _c("v-tabs-slider"),
                            _vm._v(" "),
                            _c(
                              "v-tab",
                              {
                                staticClass: "tab-element",
                                attrs: { href: "#tab-donation" },
                              },
                              [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(_vm.$t("make a donation")) +
                                    "\n                "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "v-tab-item",
                              { attrs: { value: "tab-donation" } },
                              [
                                _c(
                                  "v-card",
                                  { attrs: { flat: "", tile: "" } },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "close-modal close-donation-modal",
                                      },
                                      [
                                        _c(
                                          "svg",
                                          {
                                            staticClass:
                                              "icon icon-tabler icon-tabler-x",
                                            attrs: {
                                              xmlns:
                                                "http://www.w3.org/2000/svg",
                                              width: "28",
                                              height: "28",
                                              viewBox: "0 0 24 24",
                                              "stroke-width": "1.5",
                                              stroke: "#000000",
                                              fill: "none",
                                              "stroke-linecap": "round",
                                              "stroke-linejoin": "round",
                                            },
                                          },
                                          [
                                            _c("path", {
                                              attrs: {
                                                stroke: "none",
                                                d: "M0 0h24v24H0z",
                                                fill: "none",
                                              },
                                            }),
                                            _c("path", {
                                              attrs: { d: "M18 6l-12 12" },
                                            }),
                                            _c("path", {
                                              attrs: { d: "M6 6l12 12" },
                                            }),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c("v-card-text", [
                                      _c("div", [
                                        _c(
                                          "div",
                                          { staticClass: "donation-content" },
                                          [
                                            _c("div", [
                                              _c(
                                                "h2",
                                                { staticClass: "text-center" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t("donation_title")
                                                    )
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex justify-content-center",
                                                },
                                                [
                                                  _c(
                                                    "a",
                                                    {
                                                      staticClass:
                                                        "btn-red full mb-5",
                                                      staticStyle: {
                                                        display: "inline-flex",
                                                      },
                                                      attrs: {
                                                        href: "https://don.fondationdesmonasteres.org/soutenir-Bible-en-ses-Traditions",
                                                        target: "_blank",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "make_donation"
                                                          )
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c("div", {
                                                domProps: {
                                                  innerHTML: _vm._s(
                                                    _vm.$t("donation_content")
                                                  ),
                                                },
                                              }),
                                            ]),
                                          ]
                                        ),
                                      ]),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                    ],
                    2
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "tuto-btn btn-red mr-3",
                      attrs: {
                        title: _vm.$t("see tuto"),
                        "data-toggle": "tooltip",
                        "data-placement": "bottom",
                      },
                      on: { click: _vm.relaunchTuto },
                    },
                    [
                      _c(
                        "svg",
                        {
                          staticClass:
                            "icon icon-tabler icon-tabler-question-mark",
                          attrs: {
                            xmlns: "http://www.w3.org/2000/svg",
                            width: "30",
                            height: "30",
                            viewBox: "0 0 24 24",
                            "stroke-width": "1.5",
                            stroke: "currentColor",
                            fill: "none",
                            "stroke-linecap": "round",
                            "stroke-linejoin": "round",
                          },
                        },
                        [
                          _c("path", {
                            attrs: {
                              stroke: "none",
                              d: "M0 0h24v24H0z",
                              fill: "none",
                            },
                          }),
                          _vm._v(" "),
                          _c("path", {
                            attrs: {
                              d: "M8 8a3.5 3 0 0 1 3.5 -3h1a3.5 3 0 0 1 3.5 3a3 3 0 0 1 -2 3a3 4 0 0 0 -2 4",
                            },
                          }),
                          _vm._v(" "),
                          _c("path", { attrs: { d: "M12 19l0 .01" } }),
                        ]
                      ),
                    ]
                  ),
                ],
                1
              ),
            ]),
          ]
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }