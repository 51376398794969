var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    { attrs: { id: "app" } },
    [
      _c("navigation"),
      _vm._v(" "),
      _c("v-main", [_c("router-view", { staticClass: "page" })], 1),
      _vm._v(" "),
      _c("RegisterModal"),
      _vm._v(" "),
      _c("ModalGreetings"),
      _vm._v(" "),
      _vm._l(_vm.contentModals, function (content) {
        return _c("DraggableModal", {
          attrs: {
            modalContent: content,
            index: content.customIndex,
            mainTradition: _vm.mainTradition,
          },
        })
      }),
      _vm._v(" "),
      _c("Footer", { attrs: { "is-re-render-footer": false } }),
      _vm._v(" "),
      _c(
        "a",
        {
          staticClass: "flying-cta",
          attrs: {
            href: "https://docs.google.com/forms/d/e/1FAIpQLSdXMYobL8xgihYS7IXr-pQdFmHnSY1dQDF7NApg7XUCs3Vaig/viewform?usp=pp_url",
            title: _vm.$t("open_google_form"),
            target: "_blank",
            "data-toggle": "tooltip",
            "data-placement": "top",
            "data-html": "true",
          },
        },
        [
          _c(
            "svg",
            {
              staticClass: "icon icon-tabler icon-tabler-message-2",
              attrs: {
                xmlns: "http://www.w3.org/2000/svg",
                width: "28",
                height: "28",
                viewBox: "0 0 24 24",
                "stroke-width": "1.5",
                stroke: "#ffffff",
                fill: "none",
                "stroke-linecap": "round",
                "stroke-linejoin": "round",
              },
            },
            [
              _c("path", {
                attrs: { stroke: "none", d: "M0 0h24v24H0z", fill: "none" },
              }),
              _vm._v(" "),
              _c("path", { attrs: { d: "M8 9h8" } }),
              _vm._v(" "),
              _c("path", { attrs: { d: "M8 13h6" } }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  d: "M9 18h-3a3 3 0 0 1 -3 -3v-8a3 3 0 0 1 3 -3h12a3 3 0 0 1 3 3v8a3 3 0 0 1 -3 3h-3l-3 3l-3 -3z",
                },
              }),
            ]
          ),
        ]
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }