import ModalContentClass from "./ModalContentClass";
import BestApiService from "../services/BestApiService";

export default class VerseModalContentClass extends ModalContentClass {
    chapter = null
    constructor(id, store, fullVerse) {
        super(id, store);

        if(fullVerse) {
            const scopeAbbr = fullVerse.scope.abbr;
            this.title = scopeAbbr;
            this.chapter = this.getChapterFormScopeAbbr(scopeAbbr);
            this.content = [fullVerse];
        }

        this.initContent();
    }

    initContent() {
        if(this.title && this.content && this.chapter) {
            return;
        }

        BestApiService.fetchVerse(this.store, this.id)
            .catch(e => {
                this.title = 'unknown_modal_title';
                this.content = 'unknown_modal_content';
            })
            .then(responseData => {
                if (responseData === null || typeof responseData === 'undefined' || responseData.scope === null || typeof responseData.scope === 'undefined') {
                    this.title = 'unknown_modal_title';
                    this.content = 'unknown_modal_content';
                    throw new Error('no-op');
                }
                this.title = responseData.scope.abbr;
                this.content = responseData.verses;
                this.chapter = this.getChapterFormScopeAbbr(responseData.scope.abbr);
            })
        ;
    }

    getChapterFormScopeAbbr(scopeAbbr) {
        return scopeAbbr ? scopeAbbr.substring(scopeAbbr.indexOf(' ') + 1, scopeAbbr.indexOf(',')) : '';
    }
}