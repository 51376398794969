<template>
  <div class="tradition-configuration-wrapper">
    <div class="">
      <div class="chapter-title">{{ $t('select_chapter') }} :</div>
      <div class="chapters">
        <router-link :to="{name: 'bible', params: {bookAbbreviation:bookAbbreviation, chapter: chapter}}"
                     v-for="chapter in chapters" class="chapter" :class="currentChapter === chapter ? 'active' : ''">{{ chapter }}
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  //beforeDestroy() {
//    window.$vueEventBus.$off();
//  },
  props: {
    chapters: {
      type: Array,
      default: () => {
        return []
      }
    },
    currentChapter: {
      type: String|Number,
      default: () => {
        return null
      }
    },
    bookAbbreviation: {
      type: String,
      default: () => {
        return null
      }
    },
  },
}
</script>

<style>
</style>
