var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "popup-modal-wrapper" },
    [
      _c(
        "v-dialog",
        {
          staticClass: "poping-modal",
          attrs: { persistent: "", "max-width": "400px" },
          model: {
            value: _vm.dialog,
            callback: function ($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog",
          },
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        color: "close-popup-modal grey darken-1",
                        text: "",
                      },
                      on: {
                        click: function ($event) {
                          _vm.dialog = false
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("ignore")))]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("img", {
                staticClass: "logo-modal light-theme-item",
                attrs: {
                  src: "/images/logo-black.png",
                  title: "Logo",
                  alt: "Logo",
                },
              }),
              _vm._v(" "),
              _c("img", {
                staticClass: "logo-modal dark-theme-item",
                attrs: {
                  src: "/images/logo-white.png",
                  title: "Logo",
                  alt: "Logo",
                },
              }),
              _vm._v(" "),
              _c("v-card-title", { staticClass: "d-block" }, [
                _c("h1", [_vm._v(_vm._s(_vm.$t("register title")))]),
                _vm._v(" "),
                _c("p", [_vm._v(_vm._s(_vm.$t("register text")))]),
              ]),
              _vm._v(" "),
              _c(
                "v-card-text",
                { staticClass: "pb-7" },
                [
                  _c("v-container", { staticClass: "text-center" }, [
                    _c(
                      "a",
                      {
                        staticClass: "btn-red",
                        attrs: {
                          href: "https://don.fondationdesmonasteres.org/soutenir-Bible-en-ses-Traditions",
                          target: "_blank",
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("make_donation")))]
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }