var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        ref: "draggable-modal",
        staticClass: "custom-modal draggable-modal",
        style: {
          "z-index": _vm.modalContent.customZIndex,
          top: 30 + _vm.modalContent.customIndex + "%",
          left: 30 + _vm.modalContent.customIndex + "%",
          display: _vm.modalContent.display ? "flex" : "none",
        },
      },
      [
        _c("div", { ref: "draggable-modal-content" }, [
          _c("div", { staticClass: "header" }, [
            _vm.modalContent.title
              ? _c("div", { staticClass: "modal-title" }, [
                  _c("div", {
                    domProps: {
                      innerHTML: _vm._s(_vm.$t(_vm.modalContent.title)),
                    },
                  }),
                  _vm._v(" "),
                  _vm.modalContent instanceof _vm.VerseModalContentClass &&
                  Array.isArray(_vm.modalContent.content)
                    ? _c(
                        "div",
                        [
                          _vm.modalContent.content[0]
                            ? _c(
                                "router-link",
                                {
                                  attrs: {
                                    to: {
                                      name: "bible",
                                      params: {
                                        bookAbbreviation:
                                          _vm.getPartUrlFromScopeUrlParam(
                                            _vm.modalContent.content[0]
                                          ).abbr,
                                        chapter:
                                          _vm.getPartUrlFromScopeUrlParam(
                                            _vm.modalContent.content[0]
                                          ).chapter,
                                      },
                                      query: {
                                        verse: _vm.getPartUrlFromScopeUrlParam(
                                          _vm.modalContent.content[0]
                                        ).verse,
                                      },
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "svg",
                                    {
                                      staticClass:
                                        "icon icon-tabler icon-tabler-arrow-narrow-right",
                                      attrs: {
                                        xmlns: "http://www.w3.org/2000/svg",
                                        width: "28",
                                        height: "28",
                                        viewBox: "0 0 24 24",
                                        "stroke-width": "1.5",
                                        stroke: "#000000",
                                        fill: "none",
                                        "stroke-linecap": "round",
                                        "stroke-linejoin": "round",
                                      },
                                    },
                                    [
                                      _c("path", {
                                        attrs: {
                                          stroke: "none",
                                          d: "M0 0h24v24H0z",
                                          fill: "none",
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("path", {
                                        attrs: { d: "M5 12l14 0" },
                                      }),
                                      _vm._v(" "),
                                      _c("path", {
                                        attrs: { d: "M15 16l4 -4" },
                                      }),
                                      _vm._v(" "),
                                      _c("path", { attrs: { d: "M15 8l4 4" } }),
                                    ]
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("div", { on: { click: _vm.closeModal } }, [
              _c(
                "svg",
                {
                  staticClass: "icon icon-tabler icon-tabler-x",
                  attrs: {
                    xmlns: "http://www.w3.org/2000/svg",
                    width: "28",
                    height: "28",
                    viewBox: "0 0 24 24",
                    "stroke-width": "1.5",
                    stroke: "#000000",
                    fill: "none",
                    "stroke-linecap": "round",
                    "stroke-linejoin": "round",
                  },
                },
                [
                  _c("path", {
                    attrs: { stroke: "none", d: "M0 0h24v24H0z", fill: "none" },
                  }),
                  _c("path", { attrs: { d: "M18 6l-12 12" } }),
                  _c("path", { attrs: { d: "M6 6l12 12" } }),
                ]
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "content" }, [
            _vm.modalContent instanceof _vm.VerseModalContentClass &&
            Array.isArray(_vm.modalContent.content)
              ? _c(
                  "div",
                  _vm._l(_vm.modalContent.content, function (verse) {
                    return _c("Verse", {
                      attrs: {
                        currentChapter: _vm.modalContent.chapter,
                        verse: verse,
                        mainTradition: _vm.mainTradition,
                      },
                    })
                  }),
                  1
                )
              : _vm.modalContent.content
              ? _c("div", { staticClass: "note-content" }, [
                  _c("div", {
                    staticClass: "text-content",
                    domProps: {
                      innerHTML: _vm._s(_vm.$t(_vm.modalContent.content)),
                    },
                  }),
                ])
              : _c("div", { staticClass: "text-content" }, [
                  _c("p", [_vm._v(_vm._s(_vm.$t("note_loading")))]),
                ]),
          ]),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }