var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "bible-content-wrapper" },
      [
        _c(
          "div",
          {
            staticClass: "content-left",
            class: _vm.isPanelNoteOpen ? "panel-open" : "",
          },
          [
            _c("BibleNavigation", {
              ref: "navigation",
              attrs: {
                availableTraditions: _vm.availableTraditions,
                currentChapter: _vm.chapter,
                mainTradition: _vm.mainTradition,
              },
            }),
            _vm._v(" "),
            _c("div", { staticClass: "container content-bible" }, [
              !_vm.isLoading
                ? _c(
                    "div",
                    [
                      _vm._l(
                        _vm.uniqueVerses,
                        function (currentLoopVerse, index) {
                          return _c("Verse", {
                            key: currentLoopVerse.id,
                            ref: "verse-" + currentLoopVerse.scope.relative,
                            refInFor: true,
                            class:
                              (_vm.verse && _vm.verse - 1 == index) ||
                              (!_vm.verse && index === 0)
                                ? " tuto-highlight-first-verse"
                                : "",
                            attrs: {
                              currentChapter: _vm.chapter,
                              id: currentLoopVerse.scope.relative,
                              verse: currentLoopVerse,
                              isActiveNotesVerse:
                                _vm.noteVerseId === currentLoopVerse.id,
                              currentNote:
                                _vm.noteVerseId === currentLoopVerse.id
                                  ? _vm.currentNote
                                  : null,
                              mainTradition: _vm.mainTradition,
                              lastVerseFromChapter:
                                index + 1 === _vm.uniqueVerses.length,
                              firstVerse: index === 0,
                              locale: _vm.locale,
                              "data-verse-id": currentLoopVerse.id,
                              highlighted: _vm.highlightedVerseIds.includes(
                                "verse-" + currentLoopVerse.scope.relative
                              ),
                            },
                            nativeOn: {
                              click: function ($event) {
                                return _vm.openNotes(currentLoopVerse.id)
                              },
                            },
                          })
                        }
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "pagination" }, [
                        _c(
                          "div",
                          { staticClass: "arrow-left" },
                          [
                            _vm.prevBook && _vm.prevChapter
                              ? _c(
                                  "router-link",
                                  {
                                    ref: "prev",
                                    staticClass: "btn-box",
                                    attrs: {
                                      to: {
                                        name: "bible",
                                        params: {
                                          bookAbbreviation:
                                            _vm.prevBook.url_param,
                                          chapter: _vm.prevChapter,
                                        },
                                        query: { verse: _vm.verse },
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "svg",
                                      {
                                        staticClass: "chevron-left",
                                        attrs: {
                                          xmlns: "http://www.w3.org/2000/svg",
                                          width: "24",
                                          height: "24",
                                          viewBox: "0 0 24 24",
                                          "stroke-width": "2",
                                          stroke: "#1B2847",
                                          fill: "none",
                                          "stroke-linecap": "round",
                                          "stroke-linejoin": "round",
                                        },
                                      },
                                      [
                                        _c("path", {
                                          attrs: {
                                            stroke: "none",
                                            d: "M0 0h24v24H0z",
                                            fill: "none",
                                          },
                                        }),
                                        _c("path", {
                                          attrs: { d: "M15 6l-6 6l6 6" },
                                        }),
                                      ]
                                    ),
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(_vm.prevText) +
                                        "\n              "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "arrow-right" },
                          [
                            _vm.nextBook && _vm.nextChapter
                              ? _c(
                                  "router-link",
                                  {
                                    ref: "next",
                                    staticClass: "btn-box",
                                    attrs: {
                                      to: {
                                        name: "bible",
                                        params: {
                                          bookAbbreviation:
                                            _vm.nextBook.url_param,
                                          chapter: _vm.nextChapter,
                                        },
                                        query: { verse: _vm.verse },
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(_vm.nextText) +
                                        "\n                "
                                    ),
                                    _c(
                                      "svg",
                                      {
                                        staticClass: "chevron-right",
                                        staticStyle: { rotate: "180deg" },
                                        attrs: {
                                          xmlns: "http://www.w3.org/2000/svg",
                                          width: "24",
                                          height: "24",
                                          viewBox: "0 0 24 24",
                                          "stroke-width": "2",
                                          stroke: "#1B2847",
                                          fill: "none",
                                          "stroke-linecap": "round",
                                          "stroke-linejoin": "round",
                                        },
                                      },
                                      [
                                        _c("path", {
                                          attrs: {
                                            stroke: "none",
                                            d: "M0 0h24v24H0z",
                                            fill: "none",
                                          },
                                        }),
                                        _c("path", {
                                          attrs: { d: "M15 6l-6 6l6 6" },
                                        }),
                                      ]
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      _c("Footer", { attrs: { "is-re-render-footer": true } }),
                    ],
                    2
                  )
                : _vm.error
                ? _c("p", { staticClass: "text-danger" }, [
                    _vm._v(_vm._s(_vm.error)),
                  ])
                : _c("div", { staticClass: "text-center loader-wrapper" }, [
                    _c("div", { staticClass: "loader" }),
                  ]),
            ]),
          ],
          1
        ),
        _vm._v(" "),
        _c("Notes", {
          attrs: {
            notes: _vm.notesForVerse,
            margsRefs: _vm.margsRefByVerse,
            testament: _vm.testament,
            activeVerse: _vm.noteVerseId,
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }