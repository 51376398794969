var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "top-navigation" },
    [
      _c("div", { staticClass: "container" }, [
        _c(
          "div",
          {
            staticClass:
              "d-flex align-items-center justify-content-lg-between justify-content-center",
          },
          [
            _c(
              "div",
              {
                staticClass: "notes-opener btn-box tuto-highlight-notes",
                class: _vm.openNotesPanel ? "active" : "",
                on: {
                  click: function ($event) {
                    return _vm.openNotes()
                  },
                },
              },
              [_vm._v(_vm._s(_vm.$t("notes_title")))]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "left" }, [
              _c("div", { staticClass: "dummy" }),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "arrow-left" },
                [
                  _vm.prevBook && _vm.prevChapter
                    ? _c(
                        "router-link",
                        {
                          ref: "prev",
                          staticClass: "btn-box",
                          attrs: {
                            to: {
                              name: "bible",
                              params: {
                                bookAbbreviation: _vm.prevBook.url_param,
                                chapter: _vm.prevChapter,
                              },
                              query: { verse: _vm.verse },
                            },
                            title: _vm.prevText,
                          },
                        },
                        [
                          _c(
                            "svg",
                            {
                              staticClass: "chevron-left",
                              attrs: {
                                xmlns: "http://www.w3.org/2000/svg",
                                width: "24",
                                height: "24",
                                viewBox: "0 0 24 24",
                                "stroke-width": "2",
                                stroke: "#1B2847",
                                fill: "none",
                                "stroke-linecap": "round",
                                "stroke-linejoin": "round",
                              },
                            },
                            [
                              _c("path", {
                                attrs: {
                                  stroke: "none",
                                  d: "M0 0h24v24H0z",
                                  fill: "none",
                                },
                              }),
                              _c("path", { attrs: { d: "M15 6l-6 6l6 6" } }),
                            ]
                          ),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("div", { attrs: { id: "bible-nav" } }, [
              _c("div", { staticClass: "chapter-select-container" }, [
                _c("div", { staticClass: "d-flex align-items-center" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "tradition-badge badge-color-hover current_tradition mr-2",
                      style:
                        "--color-badge:" +
                        _vm.getTraditionObject(_vm.mainTradition).color,
                      attrs: {
                        title:
                          _vm.$t("main_tradition") +
                          " (" +
                          _vm.getTraditionObject(_vm.mainTradition).fullLabel[
                            _vm.locale
                          ] +
                          ")",
                      },
                    },
                    [_vm._v(_vm._s(_vm.mainTradition))]
                  ),
                  _vm._v(" "),
                  _vm.activeBook
                    ? _c(
                        "h2",
                        { staticClass: "text-center text-merriweather mb-0" },
                        [
                          _c(
                            "span",
                            {
                              staticClass: "text-merriweather font-weight-bold",
                            },
                            [
                              _c(
                                "router-link",
                                {
                                  attrs: {
                                    to: {
                                      name: "book",
                                      params: {
                                        bookAbbreviation: _vm.bookAbbreviation,
                                      },
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(_vm.activeBook.name))]
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              staticClass:
                                "chapter-number chapter-number-toggle",
                              on: {
                                click: function ($event) {
                                  return _vm.openPanelChapters()
                                },
                              },
                            },
                            [_vm._v(_vm._s(_vm.chapter))]
                          ),
                        ]
                      )
                    : _vm._e(),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "right" }, [
              _c(
                "div",
                { staticClass: "arrow-right tuto-highlight-next-chapter" },
                [
                  _vm.nextBook && _vm.nextChapter
                    ? _c(
                        "router-link",
                        {
                          ref: "next",
                          staticClass: "btn-box",
                          attrs: {
                            to: {
                              name: "bible",
                              params: {
                                bookAbbreviation: _vm.nextBook.url_param,
                                chapter: _vm.nextChapter,
                              },
                              query: { verse: _vm.verse },
                            },
                            title: _vm.nextText,
                          },
                        },
                        [
                          _c(
                            "svg",
                            {
                              staticClass: "chevron-right",
                              staticStyle: { rotate: "180deg" },
                              attrs: {
                                xmlns: "http://www.w3.org/2000/svg",
                                width: "24",
                                height: "24",
                                viewBox: "0 0 24 24",
                                "stroke-width": "2",
                                stroke: "#1B2847",
                                fill: "none",
                                "stroke-linecap": "round",
                                "stroke-linejoin": "round",
                              },
                            },
                            [
                              _c("path", {
                                attrs: {
                                  stroke: "none",
                                  d: "M0 0h24v24H0z",
                                  fill: "none",
                                },
                              }),
                              _c("path", { attrs: { d: "M15 6l-6 6l6 6" } }),
                            ]
                          ),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", [
                _c(
                  "div",
                  {
                    staticClass:
                      "traditions traditions-toggle tuto-highlight-tradition-config",
                    class: _vm.openPanel ? "active" : "",
                    on: {
                      click: function ($event) {
                        return _vm.openPanelTraditionConfiguration()
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n              " +
                        _vm._s(_vm.$t("traditions_config_title")) +
                        "\n              "
                    ),
                    _c(
                      "div",
                      { staticClass: "badges" },
                      _vm._l(
                        _vm.availableTraditions,
                        function (traditionAbbreviation) {
                          return _c(
                            "span",
                            {
                              staticClass: "tradition-badge",
                              style: _vm.activesTraditions.includes(
                                traditionAbbreviation
                              )
                                ? "--color-text-badge: white;--color-badge:" +
                                  _vm.getTraditionObject(traditionAbbreviation)
                                    .color +
                                  ";--full-color-badge:" +
                                  _vm.getTraditionObject(traditionAbbreviation)
                                    .color
                                : "--color-badge:" +
                                  _vm.getTraditionObject(traditionAbbreviation)
                                    .color,
                            },
                            [
                              _vm._v(
                                "\n                " +
                                  _vm._s(traditionAbbreviation) +
                                  "\n              "
                              ),
                            ]
                          )
                        }
                      ),
                      0
                    ),
                  ]
                ),
              ]),
            ]),
          ]
        ),
      ]),
      _vm._v(" "),
      _c("PanelTraditionConfiguration", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.openPanel,
            expression: "openPanel",
          },
        ],
        attrs: {
          availableTraditions: _vm.availableTraditions,
          locale: _vm.locale,
        },
      }),
      _vm._v(" "),
      _c("PanelChapters", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.openChaptersPanel,
            expression: "openChaptersPanel",
          },
        ],
        attrs: {
          chapters: _vm.chapters,
          bookAbbreviation: _vm.bookAbbreviation,
          currentChapter: _vm.currentChapter,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }