export default class ModalContentClass {
    store = null;

    id= null;
    customZIndex = 0;
    customIndex = 0;
    title = null;
    content = null;
    display = true;

    constructor(id, store) {
        this.id = id;
        this.store = store;
    }

    initContent() {
    }

    reloadContent() {
        this.initContent();
    }

    getUniqueIdBetweenNotes() {
        return this.id;
    }
}