var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "sommaire-bible container pt-18",
      class:
        _vm.book && _vm.textsPannel.length > 0
          ? "has-panel-right"
          : "no-panel-right",
    },
    [
      _c(
        "div",
        { staticClass: "content-left" },
        [
          _c(
            "router-link",
            {
              staticClass: "btn-back mb-6 tuto-highlight-back-home",
              attrs: {
                to: {
                  name: "home",
                  query: {
                    testament: this.currentTestament
                      ? this.currentTestament.name
                      : null,
                  },
                },
              },
            },
            [_vm._v("< " + _vm._s(_vm.$t("back")))]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "d-flex flex-wrap align-items-center mb-2" },
            [
              _vm.currentTestament
                ? _c(
                    "p",
                    { staticClass: "text-merriweather font-weight-bold mb-0" },
                    [_vm._v(_vm._s(_vm.currentTestament.name))]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.currentTestament
                ? _c(
                    "span",
                    {
                      staticClass: "text-merriweather font-weight-light px-2",
                      staticStyle: {
                        "font-size": "22px",
                        "line-height": "25px",
                      },
                    },
                    [_vm._v("/")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.currentTestament
                ? _c(
                    "p",
                    { staticClass: "text-merriweather font-weight-light mb-0" },
                    [_vm._v(_vm._s(_vm.groupName[_vm.locale]))]
                  )
                : _vm._e(),
            ]
          ),
          _vm._v(" "),
          !_vm.isLoadingSommaire
            ? _c("div", { staticClass: "page-content" }, [
                _vm.book
                  ? _c("div", [
                      _c(
                        "h1",
                        {
                          staticClass: "title-1-merriweather color-green mb-8",
                          style:
                            "--color-underline-book:" +
                            _vm.getColorBook(_vm.book.url_param),
                        },
                        [_vm._v(_vm._s(_vm.book.name))]
                      ),
                      _vm._v(" "),
                      _c("div", { ref: "tuto-highlight-chapters-pericopes" }, [
                        _c(
                          "h2",
                          {
                            staticClass:
                              "title-2-merriweather color-green mb-6",
                            style:
                              "--color-underline-book:" +
                              _vm.getColorBook(_vm.book.url_param),
                          },
                          [_vm._v(_vm._s(_vm.$t("chapters")))]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "chapters" },
                          _vm._l(_vm.book.chapters, function (chapter) {
                            return _c(
                              "router-link",
                              {
                                staticClass: "chapter",
                                attrs: {
                                  to: {
                                    name: "bible",
                                    params: {
                                      bookAbbreviation: _vm.bookAbbreviation,
                                      chapter: chapter.chapter,
                                    },
                                  },
                                },
                                nativeOn: {
                                  click: function ($event) {
                                    return _vm.goBook()
                                  },
                                },
                              },
                              [_vm._v(_vm._s(chapter.chapter))]
                            )
                          }),
                          1
                        ),
                        _vm._v(" "),
                        _vm.book.pericopes.length
                          ? _c("div", [
                              _c(
                                "h2",
                                {
                                  staticClass:
                                    "title-2-merriweather color-green pt-10 mt-10 mb-4",
                                  style:
                                    "--color-underline-book:" +
                                    _vm.getColorBook(_vm.book.url_param),
                                },
                                [_vm._v(_vm._s(_vm.$t("pericopes")))]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "grid-pericopes" },
                                _vm._l(_vm.book.pericopes, function (pericope) {
                                  return _c(
                                    "div",
                                    [
                                      _c(
                                        "router-link",
                                        {
                                          staticClass: "text-tiny-merriweather",
                                          attrs: {
                                            to: {
                                              name: "bible",
                                              params: {
                                                bookAbbreviation:
                                                  _vm.bookAbbreviation,
                                                chapter:
                                                  _vm.getChapterFromScope(
                                                    pericope.scope.relative
                                                  ),
                                              },
                                              query: {
                                                verse: _vm.getVerseFromScope(
                                                  _vm.bookAbbreviation,
                                                  pericope.scope.relative
                                                ),
                                              },
                                            },
                                          },
                                          nativeOn: {
                                            click: function ($event) {
                                              return _vm.goBook()
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "strong",
                                            {
                                              staticClass:
                                                "text-tiny-merriweather",
                                            },
                                            [_vm._v(_vm._s(pericope.title))]
                                          ),
                                          _c("br"),
                                          _vm._v(
                                            "(" +
                                              _vm._s(pericope.scope.relative) +
                                              ")\n                "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                }),
                                0
                              ),
                            ])
                          : _vm._e(),
                      ]),
                    ])
                  : _vm._e(),
              ])
            : _c("p", [
                _vm._v("\n      " + _vm._s(_vm.$t("loading")) + "\n    "),
              ]),
        ],
        1
      ),
      _vm._v(" "),
      _vm.book && _vm.textsPannel.length > 0
        ? _c(
            "div",
            { staticClass: "panel-right tuto-highlight-introduction" },
            [
              _c("div", { staticClass: "panel-wrapper" }, [
                _c("div", { staticClass: "panel-summary-subtitle" }, [
                  _vm._v(_vm._s(_vm.book.name.toLowerCase())),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "panel-summary-title" }, [
                  _vm._v(_vm._s(_vm.$t("about"))),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "panel-text-wrapper" }, [
                  !_vm.isLoadingPanel
                    ? _c(
                        "div",
                        _vm._l(_vm.textsPannel, function (textPannel, index) {
                          return _c("div", { staticClass: "panel-collapse" }, [
                            textPannel.title
                              ? _c(
                                  "p",
                                  {
                                    staticClass: "panel-collapse-title",
                                    on: {
                                      click: function ($event) {
                                        return _vm.toggleText(index, $event)
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "svg",
                                      {
                                        staticClass: "chevron-right",
                                        attrs: {
                                          xmlns: "http://www.w3.org/2000/svg",
                                          width: "20",
                                          height: "20",
                                          viewBox: "0 0 24 24",
                                          "stroke-width": "3",
                                          stroke: "#1B2847",
                                          fill: "none",
                                          "stroke-linecap": "round",
                                          "stroke-linejoin": "round",
                                        },
                                      },
                                      [
                                        _c("path", {
                                          attrs: {
                                            stroke: "none",
                                            d: "M0 0h24v24H0z",
                                            fill: "none",
                                          },
                                        }),
                                        _c("path", {
                                          attrs: { d: "M9 6l6 6l-6 6" },
                                        }),
                                      ]
                                    ),
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(textPannel.title) +
                                        "\n            "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "panel-collapse-content-wrapper note-content",
                                class: textPannel.title ? "hide-content" : "",
                                attrs: { "data-text-toggled": index },
                              },
                              [
                                _c("div", {
                                  staticClass: "panel-collapse-content",
                                  domProps: {
                                    innerHTML: _vm._s(textPannel.content),
                                  },
                                }),
                              ]
                            ),
                          ])
                        }),
                        0
                      )
                    : _c("p", [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$t("loading")) +
                            "\n        "
                        ),
                      ]),
                ]),
              ]),
            ]
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }