var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-menu",
    {
      attrs: { "close-on-content-click": false, "offset-x": "" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function (ref) {
            var on = ref.on
            var attrs = ref.attrs
            return [
              _c(
                "v-btn",
                _vm._g(
                  _vm._b(
                    { staticClass: "register-btn btn-red mr-3" },
                    "v-btn",
                    attrs,
                    false
                  ),
                  on
                ),
                [_vm._v(_vm._s(_vm.$t("register")))]
              ),
            ]
          },
        },
      ]),
      model: {
        value: _vm.menu,
        callback: function ($$v) {
          _vm.menu = $$v
        },
        expression: "menu",
      },
    },
    [
      _vm._v(" "),
      [
        _c(
          "v-tabs",
          { staticClass: "elevation-2 panel-register" },
          [
            _c("v-tabs-slider"),
            _vm._v(" "),
            !_vm.token
              ? _c(
                  "v-tab",
                  {
                    staticClass: "tab-element",
                    attrs: { href: "#tab-create" },
                  },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.$t("create account")) +
                        "\n      "
                    ),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            !_vm.token
              ? _c(
                  "v-tab-item",
                  { attrs: { value: "tab-create" } },
                  [
                    _c(
                      "v-card",
                      { attrs: { flat: "", tile: "" } },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "close-modal close-registration-modal",
                          },
                          [
                            _c(
                              "svg",
                              {
                                staticClass: "icon icon-tabler icon-tabler-x",
                                attrs: {
                                  xmlns: "http://www.w3.org/2000/svg",
                                  width: "28",
                                  height: "28",
                                  viewBox: "0 0 24 24",
                                  "stroke-width": "1.5",
                                  stroke: "#000000",
                                  fill: "none",
                                  "stroke-linecap": "round",
                                  "stroke-linejoin": "round",
                                },
                              },
                              [
                                _c("path", {
                                  attrs: {
                                    stroke: "none",
                                    d: "M0 0h24v24H0z",
                                    fill: "none",
                                  },
                                }),
                                _c("path", { attrs: { d: "M18 6l-12 12" } }),
                                _c("path", { attrs: { d: "M6 6l12 12" } }),
                              ]
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("v-card-text", [
                          _c("div", [
                            _vm.success === true
                              ? _c(
                                  "div",
                                  { staticClass: "registration-form-validate" },
                                  [
                                    _c("div", [
                                      _c("h2", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t("account_created_title")
                                          )
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("div", {
                                        domProps: {
                                          innerHTML: _vm._s(
                                            _vm.$t("account_created_text")
                                          ),
                                        },
                                      }),
                                    ]),
                                    _vm._v(" "),
                                    _c("div", [
                                      _c("h2", [
                                        _vm._v(
                                          _vm._s(_vm.$t("account_donation"))
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("p", {
                                        domProps: {
                                          innerHTML: _vm._s(
                                            _vm.$t("account_donation_text")
                                          ),
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "a",
                                        {
                                          staticClass: "btn-red full",
                                          staticStyle: {
                                            display: "inline-block",
                                          },
                                          attrs: {
                                            href: "https://don.fondationdesmonasteres.org/soutenir-Bible-en-ses-Traditions",
                                            target: "_blank",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.$t("make_donation"))
                                          ),
                                        ]
                                      ),
                                    ]),
                                  ]
                                )
                              : _c(
                                  "div",
                                  { staticClass: "registration-form-wrapper" },
                                  [
                                    _c("div", [
                                      _c("h1", [
                                        _vm._v(
                                          _vm._s(_vm.$t("register_title"))
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("h2", [
                                        _vm._v(
                                          _vm._s(_vm.$t("register_subtitle"))
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("div", {
                                        domProps: {
                                          innerHTML: _vm._s(
                                            _vm.$t("register_content")
                                          ),
                                        },
                                      }),
                                    ]),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "right" }, [
                                      _c("h2", [
                                        _vm._v(
                                          _vm._s(_vm.$t("create_account"))
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        _vm._l(_vm.errors, function (error) {
                                          return _c("div", {
                                            staticClass: "alert alert-danger",
                                            attrs: { role: "alert" },
                                            domProps: {
                                              innerHTML: _vm._s(error),
                                            },
                                          })
                                        }),
                                        0
                                      ),
                                      _vm._v(" "),
                                      _c("div", [
                                        _c(
                                          "form",
                                          { on: { submit: _vm.submitForm } },
                                          [
                                            _c("div", { staticClass: "row" }, [
                                              _c(
                                                "div",
                                                { staticClass: "col-md-6" },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "form-group",
                                                    },
                                                    [
                                                      _c(
                                                        "label",
                                                        {
                                                          attrs: {
                                                            for: "lastname",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t("lastname")
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c("input", {
                                                        directives: [
                                                          {
                                                            name: "model",
                                                            rawName: "v-model",
                                                            value: _vm.lastname,
                                                            expression:
                                                              "lastname",
                                                          },
                                                        ],
                                                        staticClass:
                                                          "form-control",
                                                        attrs: {
                                                          name: "lastname",
                                                          id: "lastname",
                                                          type: "text",
                                                          placeholder: _vm.$t(
                                                            "lastname_placeholder"
                                                          ),
                                                          required: "",
                                                        },
                                                        domProps: {
                                                          value: _vm.lastname,
                                                        },
                                                        on: {
                                                          input: function (
                                                            $event
                                                          ) {
                                                            if (
                                                              $event.target
                                                                .composing
                                                            ) {
                                                              return
                                                            }
                                                            _vm.lastname =
                                                              $event.target.value
                                                          },
                                                        },
                                                      }),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                { staticClass: "col-md-6" },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "form-group",
                                                    },
                                                    [
                                                      _c(
                                                        "label",
                                                        {
                                                          attrs: {
                                                            for: "firstname",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "firstname"
                                                              )
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c("input", {
                                                        directives: [
                                                          {
                                                            name: "model",
                                                            rawName: "v-model",
                                                            value:
                                                              _vm.firstname,
                                                            expression:
                                                              "firstname",
                                                          },
                                                        ],
                                                        staticClass:
                                                          "form-control",
                                                        attrs: {
                                                          name: "firstname",
                                                          id: "firstname",
                                                          type: "text",
                                                          placeholder: _vm.$t(
                                                            "firstname_placeholder"
                                                          ),
                                                          required: "",
                                                        },
                                                        domProps: {
                                                          value: _vm.firstname,
                                                        },
                                                        on: {
                                                          input: function (
                                                            $event
                                                          ) {
                                                            if (
                                                              $event.target
                                                                .composing
                                                            ) {
                                                              return
                                                            }
                                                            _vm.firstname =
                                                              $event.target.value
                                                          },
                                                        },
                                                      }),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              { staticClass: "form-group" },
                                              [
                                                _c(
                                                  "label",
                                                  { attrs: { for: "email" } },
                                                  [
                                                    _vm._v(
                                                      _vm._s(_vm.$t("email"))
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value: _vm.email,
                                                      expression: "email",
                                                    },
                                                  ],
                                                  staticClass: "form-control",
                                                  attrs: {
                                                    name: "email",
                                                    type: "email",
                                                    id: "email",
                                                    placeholder:
                                                      _vm.$t(
                                                        "email_placeholder"
                                                      ),
                                                    required: "",
                                                  },
                                                  domProps: {
                                                    value: _vm.email,
                                                  },
                                                  on: {
                                                    input: function ($event) {
                                                      if (
                                                        $event.target.composing
                                                      ) {
                                                        return
                                                      }
                                                      _vm.email =
                                                        $event.target.value
                                                    },
                                                  },
                                                }),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c("div", { staticClass: "row" }, [
                                              _c(
                                                "div",
                                                { staticClass: "col-md-6" },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "form-group",
                                                    },
                                                    [
                                                      _c(
                                                        "label",
                                                        {
                                                          attrs: {
                                                            for: "password",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "password_create"
                                                              )
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c("input", {
                                                        directives: [
                                                          {
                                                            name: "model",
                                                            rawName: "v-model",
                                                            value: _vm.password,
                                                            expression:
                                                              "password",
                                                          },
                                                        ],
                                                        staticClass:
                                                          "form-control",
                                                        attrs: {
                                                          name: "first",
                                                          id: "password_first",
                                                          type: "password",
                                                          placeholder: _vm.$t(
                                                            "password_placeholder"
                                                          ),
                                                          required: "",
                                                        },
                                                        domProps: {
                                                          value: _vm.password,
                                                        },
                                                        on: {
                                                          input: function (
                                                            $event
                                                          ) {
                                                            if (
                                                              $event.target
                                                                .composing
                                                            ) {
                                                              return
                                                            }
                                                            _vm.password =
                                                              $event.target.value
                                                          },
                                                        },
                                                      }),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                { staticClass: "col-md-6" },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "form-group",
                                                    },
                                                    [
                                                      _c(
                                                        "label",
                                                        {
                                                          attrs: {
                                                            for: "repeated_password",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "repeated_password"
                                                              )
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c("input", {
                                                        directives: [
                                                          {
                                                            name: "model",
                                                            rawName: "v-model",
                                                            value:
                                                              _vm.repeatedPassword,
                                                            expression:
                                                              "repeatedPassword",
                                                          },
                                                        ],
                                                        staticClass:
                                                          "form-control",
                                                        attrs: {
                                                          name: "second",
                                                          id: "password_second",
                                                          type: "password",
                                                          required: "",
                                                        },
                                                        domProps: {
                                                          value:
                                                            _vm.repeatedPassword,
                                                        },
                                                        on: {
                                                          input: function (
                                                            $event
                                                          ) {
                                                            if (
                                                              $event.target
                                                                .composing
                                                            ) {
                                                              return
                                                            }
                                                            _vm.repeatedPassword =
                                                              $event.target.value
                                                          },
                                                        },
                                                      }),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                staticClass: "form-group mt-2",
                                              },
                                              [
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value: _vm.conditions,
                                                      expression: "conditions",
                                                    },
                                                  ],
                                                  attrs: {
                                                    name: "conditions",
                                                    id: "conditions",
                                                    type: "checkbox",
                                                    required: "",
                                                  },
                                                  domProps: {
                                                    checked: Array.isArray(
                                                      _vm.conditions
                                                    )
                                                      ? _vm._i(
                                                          _vm.conditions,
                                                          null
                                                        ) > -1
                                                      : _vm.conditions,
                                                  },
                                                  on: {
                                                    change: function ($event) {
                                                      var $$a = _vm.conditions,
                                                        $$el = $event.target,
                                                        $$c = $$el.checked
                                                          ? true
                                                          : false
                                                      if (Array.isArray($$a)) {
                                                        var $$v = null,
                                                          $$i = _vm._i($$a, $$v)
                                                        if ($$el.checked) {
                                                          $$i < 0 &&
                                                            (_vm.conditions =
                                                              $$a.concat([$$v]))
                                                        } else {
                                                          $$i > -1 &&
                                                            (_vm.conditions =
                                                              $$a
                                                                .slice(0, $$i)
                                                                .concat(
                                                                  $$a.slice(
                                                                    $$i + 1
                                                                  )
                                                                ))
                                                        }
                                                      } else {
                                                        _vm.conditions = $$c
                                                      }
                                                    },
                                                  },
                                                }),
                                                _vm._v(" "),
                                                _c(
                                                  "label",
                                                  {
                                                    staticStyle: {
                                                      color: "black",
                                                    },
                                                    attrs: {
                                                      for: "conditions",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t("conditions")
                                                      ) + " ("
                                                    ),
                                                    _c(
                                                      "a",
                                                      {
                                                        attrs: {
                                                          href: _vm.Routing.generate(
                                                            "front_conditions"
                                                          ),
                                                          target: "_blank",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(_vm.$t("see"))
                                                        ),
                                                      ]
                                                    ),
                                                    _vm._v(")"),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c("div", {
                                              staticClass: "g-recaptcha",
                                              attrs: {
                                                id: "recaptchaRegistration",
                                                name: "captcha",
                                              },
                                            }),
                                            _vm._v(" "),
                                            _c(
                                              "button",
                                              {
                                                staticClass:
                                                  "btn-red full mt-2",
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "create_account_button"
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]),
                                    ]),
                                  ]
                                ),
                          ]),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ],
          1
        ),
      ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }