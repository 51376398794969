var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "input-wrapper tuto-highlight-research" }, [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.queryTerm,
            expression: "queryTerm",
          },
        ],
        ref: "recherche-input",
        attrs: { type: "text", placeholder: _vm.$t("search placeholder") },
        domProps: { value: _vm.queryTerm },
        on: {
          click: _vm.openResearchTable,
          keyup: _vm.loadEntries,
          input: function ($event) {
            if ($event.target.composing) {
              return
            }
            _vm.queryTerm = $event.target.value
          },
        },
      }),
      _vm._v(" "),
      _vm.queryTerm.length > 0
        ? _c(
            "div",
            { staticClass: "close-search", on: { click: _vm.emptyResearch } },
            [
              _c(
                "svg",
                {
                  staticClass: "icon icon-tabler icon-tabler-x",
                  attrs: {
                    xmlns: "http://www.w3.org/2000/svg",
                    width: "24",
                    height: "24",
                    viewBox: "0 0 24 24",
                    "stroke-width": "1.5",
                    stroke: "#004EDE",
                    fill: "none",
                    "stroke-linecap": "round",
                    "stroke-linejoin": "round",
                  },
                },
                [
                  _c("path", {
                    attrs: { stroke: "none", d: "M0 0h24v24H0z", fill: "none" },
                  }),
                  _c("path", { attrs: { d: "M18 6l-12 12" } }),
                  _c("path", { attrs: { d: "M6 6l12 12" } }),
                ]
              ),
            ]
          )
        : _vm._e(),
    ]),
    _vm._v(" "),
    _vm.token && _vm.openModal
      ? _c(
          "div",
          { ref: "recherche-panel", staticClass: "customModalRecherche" },
          [
            _vm.queryTerm
              ? _c("div", [
                  _vm.isLoading
                    ? _c(
                        "div",
                        { staticClass: "bloc-reload" },
                        [
                          _c("v-progress-circular", {
                            attrs: { indeterminate: "" },
                          }),
                        ],
                        1
                      )
                    : _c(
                        "div",
                        [
                          _c(
                            "v-tabs",
                            {
                              staticClass: "elevation-2 text-search-results",
                              attrs: { "align-tabs": "center" },
                            },
                            [
                              _vm._l(_vm.items, function (tab) {
                                return _c(
                                  "v-tab",
                                  {
                                    staticClass: "tab-element",
                                    attrs: { href: "#" + tab.label },
                                  },
                                  [
                                    _vm._v(
                                      "\n            " +
                                        _vm._s(
                                          tab.items.length +
                                            " " +
                                            _vm.$t("search_note_in") +
                                            " " +
                                            _vm.$t(tab.label)
                                        ) +
                                        "\n          "
                                    ),
                                  ]
                                )
                              }),
                              _vm._v(" "),
                              _vm._l(_vm.items, function (tab) {
                                return _c(
                                  "v-tab-item",
                                  { attrs: { value: tab.label } },
                                  [
                                    _c(
                                      "v-card",
                                      { attrs: { flat: "", tile: "" } },
                                      [
                                        _c(
                                          "v-card-text",
                                          _vm._l(tab.items, function (item) {
                                            return _c("v-list-item", [
                                              tab.label === "verses"
                                                ? _c(
                                                    "div",
                                                    { staticClass: "verse" },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass: "xref",
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.openVerse(
                                                                item
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "scope",
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  item.scope
                                                                    .abbr
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                          _vm._v(" "),
                                                          _c("div", {
                                                            staticClass:
                                                              "result",
                                                            domProps: {
                                                              innerHTML: _vm._s(
                                                                _vm.getHighlightedElementText(
                                                                  item.incipit
                                                                )
                                                              ),
                                                            },
                                                          }),
                                                        ]
                                                      ),
                                                    ]
                                                  )
                                                : tab.label === "notes"
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass: "note_xref",
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.openNote(
                                                            item
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass: "result",
                                                        },
                                                        [
                                                          _c("p", [
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "note-type",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    item.scope
                                                                      .abbr
                                                                  ) +
                                                                    " (" +
                                                                    _vm._s(
                                                                      _vm.getFullNoteType(
                                                                        item.type
                                                                      )
                                                                    ) +
                                                                    ")"
                                                                ),
                                                              ]
                                                            ),
                                                            _vm._v(" "),
                                                            _c("strong", {
                                                              domProps: {
                                                                innerHTML:
                                                                  _vm._s(
                                                                    _vm.getHighlightedElementText(
                                                                      item.title
                                                                    )
                                                                  ),
                                                              },
                                                            }),
                                                          ]),
                                                          _vm._v(" "),
                                                          _c("div", {
                                                            staticClass:
                                                              "cut-result",
                                                            domProps: {
                                                              innerHTML: _vm._s(
                                                                _vm.getHighlightedElementText(
                                                                  item.incipit
                                                                )
                                                              ),
                                                            },
                                                          }),
                                                        ]
                                                      ),
                                                    ]
                                                  )
                                                : tab.label === "synthNotes"
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass: "synth_link",
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.openSynthNote(
                                                            item
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c("div", {
                                                        staticClass: "result",
                                                        domProps: {
                                                          innerHTML: _vm._s(
                                                            item.title
                                                          ),
                                                        },
                                                      }),
                                                    ]
                                                  )
                                                : tab.label === "margRefs"
                                                ? _c("div", [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "margref-item",
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "scope",
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                item.scope.abbr
                                                              )
                                                            ),
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _c("div", {
                                                          staticClass: "result",
                                                          domProps: {
                                                            innerHTML: _vm._s(
                                                              item.incipit
                                                            ),
                                                          },
                                                        }),
                                                      ]
                                                    ),
                                                  ])
                                                : _vm._e(),
                                            ])
                                          }),
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              }),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                ])
              : _c(
                  "div",
                  [
                    _c(
                      "v-tabs",
                      { staticClass: "elevation-2 testament-search" },
                      [
                        _c("v-tabs-slider"),
                        _vm._v(" "),
                        _c(
                          "v-tab",
                          {
                            staticClass: "tab-element",
                            attrs: { href: "#tab-AT" },
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.$t("old testament")) +
                                "\n        "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "v-tab",
                          {
                            staticClass: "tab-element",
                            attrs: { href: "#tab-NT" },
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.$t("new testament")) +
                                "\n        "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "v-tab-item",
                          { attrs: { value: "tab-AT" } },
                          [
                            _c(
                              "v-card",
                              { attrs: { flat: "", tile: "" } },
                              [
                                _c(
                                  "v-card-text",
                                  _vm._l(_vm.AT, function (group, index) {
                                    return _c(
                                      "v-list-item",
                                      {
                                        key: index,
                                        on: { click: _vm.closeResearchTable },
                                      },
                                      _vm._l(
                                        group.books,
                                        function (abbreviation) {
                                          return _c(
                                            "router-link",
                                            {
                                              key: abbreviation,
                                              class:
                                                "book " +
                                                (_vm.bookByAbbreviation(
                                                  abbreviation
                                                )
                                                  ? "book-" +
                                                    _vm.bookByAbbreviation(
                                                      abbreviation
                                                    ).url_param
                                                  : ""),
                                              attrs: {
                                                to: _vm.bookByAbbreviation(
                                                  abbreviation
                                                )
                                                  ? "/bible/" +
                                                    _vm.bookByAbbreviation(
                                                      abbreviation
                                                    ).url_param +
                                                    "/1"
                                                  : "#",
                                                title: _vm.bookByAbbreviation(
                                                  abbreviation
                                                )
                                                  ? _vm.bookByAbbreviation(
                                                      abbreviation
                                                    ).name
                                                  : "",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(abbreviation) +
                                                  "\n                "
                                              ),
                                            ]
                                          )
                                        }
                                      ),
                                      1
                                    )
                                  }),
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "v-tab-item",
                          { attrs: { value: "tab-NT" } },
                          [
                            _c(
                              "v-card",
                              { attrs: { flat: "", tile: "" } },
                              [
                                _c(
                                  "v-card-text",
                                  _vm._l(_vm.NT, function (group, index) {
                                    return _c(
                                      "v-list-item",
                                      {
                                        key: index,
                                        on: { click: _vm.closeResearchTable },
                                      },
                                      _vm._l(
                                        group.books,
                                        function (abbreviation) {
                                          return _c(
                                            "router-link",
                                            {
                                              key: abbreviation,
                                              class:
                                                "book " +
                                                (_vm.bookByAbbreviation(
                                                  abbreviation
                                                )
                                                  ? "book-" +
                                                    _vm.bookByAbbreviation(
                                                      abbreviation
                                                    ).url_param
                                                  : ""),
                                              attrs: {
                                                to: _vm.bookByAbbreviation(
                                                  abbreviation
                                                )
                                                  ? "/bible/" +
                                                    _vm.bookByAbbreviation(
                                                      abbreviation
                                                    ).url_param +
                                                    "/1"
                                                  : "#",
                                                title: _vm.bookByAbbreviation(
                                                  abbreviation
                                                )
                                                  ? _vm.bookByAbbreviation(
                                                      abbreviation
                                                    ).name
                                                  : "",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(abbreviation) +
                                                  "\n                "
                                              ),
                                            ]
                                          )
                                        }
                                      ),
                                      1
                                    )
                                  }),
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
          ]
        )
      : !_vm.token
      ? _c("div", { staticClass: "no-access-popin" }, [
          _c("div", { staticClass: "popin" }, [
            _c("p", {
              domProps: { innerHTML: _vm._s(_vm.$t("inaccessible_search")) },
            }),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }