const routes = require('../fos_js_routes.json');
import Routing from '../../../vendor/friendsofsymfony/jsrouting-bundle/Resources/public/js/router.min.js';

const params = {
  routes: Routing.setRoutingData(routes),
}

function _getToken() {
  return localStorage.getItem('token');
}
// Return headers with authorization for donors; empty headers for non-donors.
function _getApiAuthorizationHeaders(store) {
  if (store.getters.isPaying()) {
    return {
      Authorization: `Bearer ${localStorage.getItem('api_token')}`
    };
  }
  return {};
}
function _handleErrors(response) {
  if (response.status === 401) {
    _logout();
  }
  return response.json();
}

function _handleErrorsCustomized(response) {
  if (response.status === 401) {
    _logout();
  }
  return response;
}
function _logout() {
  if (_getToken()) { // do not clear localStorage token if params is uninitialized!
    localStorage.removeItem('token');
    localStorage.removeItem('fullname');
  } else {
    _getToken();
  }
}

export default {
  comment(json) {
    return fetch(Routing.generate('front_contact_json'), {
      method: 'POST',
      body: json,
      headers: {
        'Content-Type': 'application/json',
      }
    }).then(_handleErrors);
  },
  editConfigUser(json) {
    return fetch(Routing.generate('api_user_edit_config'), {
      method: 'PUT',
      body: JSON.stringify(json),
      headers: { JWT: 'Bearer ' + _getToken() },
    }).then(_handleErrors);
  },
  donate(json) {
    return fetch(Routing.generate('api_user_donate'), {
      method: 'POST',
      body: json,
      headers: { JWT: 'Bearer ' + _getToken() },
    }).then(_handleErrors);
  },
  fetchToken(username, password) {
    return fetch(Routing.generate('api_login_check'), {
      method: 'POST',
      body: JSON.stringify({username, password}),
      headers: {
        'Content-Type': 'application/json',
      }
    }).then(_handleErrors);
  },
  updateAccount(user) {
    return fetch(Routing.generate('api_user_update_account'), {
      method: 'POST',
      body: JSON.stringify({front_user_update: user}),
      headers: {
        'Content-Type': 'application/json',
        JWT: 'Bearer ' + _getToken(),
      }
    }).then(_handleErrors);
  },
  fetchBibliographicReferences(store, id) {
      const url = store.getters.getBibleApiUrl();
      const edition = store.getters.getBibleEdition();
      return fetch( url + 'bibliographic-references/' + id , {
          headers: _getApiAuthorizationHeaders(store),
      }).then(_handleErrorsCustomized);
  },
  fetchVerse(store, scope) {
      const url = store.getters.getBibleApiUrl();
      const edition = store.getters.getBibleEdition();
      return fetch( url + 'verses/' + edition + '/' + scope, {
          headers: _getApiAuthorizationHeaders(store)
      }).then(_handleErrors);
  },
  fetchToc(store) {
    const url = store.getters.getBibleApiUrl();
    const edition = store.getters.getBibleEdition();
    return fetch(url + 'toc/' + edition, {
      headers: _getApiAuthorizationHeaders(store)
    }).then(_handleErrors);
  },
    fetchBookInformations(store, id) {
    const url = store.getters.getBibleApiUrl();
    const edition = store.getters.getBibleEdition();
    return fetch(`${url}toc/${edition}/${id}`, {
      headers: _getApiAuthorizationHeaders(store)
    }).then(_handleErrors);
  },
  fetchBook(store, id) {
    const url = store.getters.getBibleApiUrl();
    const edition = store.getters.getBibleEdition();
    return fetch(url + 'bible/' + edition + '/' + id, {
      headers: _getApiAuthorizationHeaders(store)
    }).then(_handleErrors);
  },
  fetchRubrics(store) {
    const url = store.getters.getBibleApiUrl();
    const edition = store.getters.getBibleEdition();
    return fetch(url + 'rubrics/' + edition, {
      headers: _getApiAuthorizationHeaders(store)
    }).then(_handleErrors);
  },
  fetchSynthNotes(store) {
    const url = store.getters.getBibleApiUrl();
    const edition = store.getters.getBibleEdition();
    return fetch(url + 'synthetic-notes/' + edition, {
      headers: _getApiAuthorizationHeaders(store),
    }).then(_handleErrors);
  },
  fetchIntroductions(store) {
    const url = store.getters.getBibleApiUrl();
    const edition = store.getters.getBibleEdition();
    return fetch(url + 'introductions/' + edition, {
      headers: _getApiAuthorizationHeaders(store),
    }).then(_handleErrors);
  },
  fetchUser() {
    return fetch(Routing.generate('api_user_get'), {
      headers: { JWT: 'Bearer ' + _getToken() },
    }).then(_handleErrors);
  },
  getRubrics(store, wantReload, callback) {
    if (!wantReload && store.getters.getRubrics()) {
      callback(store.getters.getRubrics());
    } else {
      this.fetchRubrics(store).then(json => {
        var registers = { 'AT': {}, 'NT': {} };
        for (const register of json.registers) {
          registers['AT'][register.id] = {};
          registers['NT'][register.id] = {};
        }
        for (const rubric of json.rubrics) {
          if (rubric.register_ot) {
            const num = rubric.register_ot.replace('#/registers/', '');
            const register = json.registers[num].id;
            registers['AT'][register][rubric.id] = rubric.title;
            registers['AT'][register][rubric.abbr] = rubric.title;
          } else {
            registers['AT']['text'][rubric.id] = rubric.title;
            registers['AT']['text'][rubric.abbr] = rubric.title;
          }
          if (rubric.register_nt) {
            const num = rubric.register_nt.replace('#/registers/', '');
            const register = json.registers[num].id;
            registers['NT'][register][rubric.id] = rubric.title;
            registers['NT'][register][rubric.abbr] = rubric.title;
          } else {
            registers['NT']['text'][rubric.id] = rubric.title;
            registers['NT']['text'][rubric.abbr] = rubric.title;
          }
        }
        registers.save = true;
        registers.edition = store.getters.getBibleEdition();
        store.commit('SET_RUBRICS', registers);
        callback(registers);
      });
    }
  },
  getSynthNotes(store, wantReload, callback) {
    if (!wantReload && store.getters.isSynthNotesLoaded()) {
      callback(store.getters.getSynthNotes());
    } else {
      this.fetchSynthNotes(store)
        .then(json => {
          json.edition = store.getters.getBibleEdition();
          store.commit('SET_SYNTH_NOTES', json);
          window.$vueEventBus.$emit('synth_notes');
          callback(json);
        });
    }
  },
  getIntroductions(store, wantReload, callback) {
    if (!wantReload && store.getters.isIntroductionsLoaded()) {
      callback(store.getters.getIntroductions());
    } else {
      this.fetchIntroductions(store)
        .then(json => {
          json.edition = store.getters.getBibleEdition();
          store.commit('SET_INTRODUCTIONS', json);
          window.$vueEventBus.$emit('introductions');
          callback(json);
        });
    }
  },
  getToc(store, wantReload, callback) {
    if (!wantReload && store.getters.getToc()) {
      callback(store.getters.getToc());
    } else {
      this.fetchToc(store).then(json => {
        json.save = true;
        json.edition = store.getters.getBibleEdition();
        store.commit('SET_TOC', json);
        callback(json);
      });
    }
  },
  getBookInformations(store, id, wantReload, callback) {
    if (!wantReload && store.getters.getBookInformationsByAbbreviation(id)) {
      callback(store.getters.getBookInformationsByAbbreviation(id));
    } else {
      this.fetchBookInformations(store, id).then(json => {
        json.edition = store.getters.getBibleEdition();
        store.commit('SET_BOOK_INFORMATIONS', {
            edition: store.getters.getBibleEdition(),
            id: id,
            content: json
        });
        callback(json);
      });
    }
  },
  register(json) {
    return fetch(Routing.generate('api_user_register'), {
      method: 'POST',
      body: json,
      headers: {
        'Content-Type': 'application/json',
      }
    }).then(_handleErrors);
  },
  search(store, term) {
    const url = store.getters.getBibleApiUrl();
    const edition = store.getters.getBibleEdition();
    return fetch(url + 'search?edition=' + edition + '&fields=incipit,combined,title&limit=200&text=' + encodeURIComponent(term), {
      cache: 'force-cache',
      headers: _getApiAuthorizationHeaders(store)
    }).then(_handleErrors);
  },
  fetchMediaAuthorizationToken(store) {

    const auth = store.getters.getMediaAuthorization();

    if (auth.token && auth.exp && Date.now() < auth.exp * 1000) {
      // Token is still valid.
      return Promise.resolve(auth.token);
    }

    // Fetch a new token.
    return fetch(Routing.generate('media_auth'))
      .then(_handleErrors)
      .then(json => {
        store.commit('SET_MEDIA_AUTHORIZATION', {
          token: json.access_token,
          exp: Math.floor(Date.now() / 1000) + json.expires_in,
          type: json.token_type
        });
        return json.access_token;
      });
  }
}
