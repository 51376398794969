import ModalContentClass from "./ModalContentClass";
import BestApiService from "../services/BestApiService";
import MediaFetcher from "./MediaFetcher";
import NoteTypeMatcher from "./Utils/NoteTypeMatcher";

export default class NoteModalContentClass extends ModalContentClass {
    bookAbbreviation = null;
    book = null;
    rubric = null;

    constructor(id, store, rubric, fullNote) {
        super(id, store);
        this.bookAbbreviation = this.id.match(/^([1-3][a-zA-Z]+)|[a-zA-Z]+/)[0];
        this.rubric = rubric;

        this.initRegisters();

        if(fullNote) {
            this.fillContent(fullNote)
        }
        this.initContent();
    }

    initContent() {
        if(this.title && this.content) {
            return;
        }

        if (this.store.getters.getBook(this.bookAbbreviation)) {
            this.book = this.store.getters.getBook(this.bookAbbreviation);
            this.setContentNote();
            return;
        }

        BestApiService.fetchBook(this.store, this.bookAbbreviation)
            .then(responseData => {
                if (responseData === null || typeof responseData === 'undefined') {
                    throw new Error('no-op');
                }
                this.store.commit('SET_BOOK', {
                    id: this.bookAbbreviation,
                    edition: this.store.getters.getBibleEdition(),
                    content: responseData,
                });

                this.book = responseData;
                return this.setContentNote();
            })
        ;
    }

    async setContentNote() {
        let note=null
        if(this.book.notes){
            note = this.book.notes.find(note => note.scope.url_param === this.id && note.type === this.rubric);
        }

        if(note) {
            await this.fillContent(note)
        } else if(!this.store.getters.isPaying()) {
            this.title = 'premium_modal_title';
            this.content = 'premium_modal_content';
        } else {
            this.title = 'unknown_modal_title';
            this.content = 'unknown_modal_content';
        }
    }
    initRegisters() {
        BestApiService.getRubrics(this.store, false,(registers) => {
            this.registers = registers['AT']
        });
    }

    async fillContent(note) {
        this.title = `<span style="color:#C91B23;font-weight: normal">${note.scope.abbr} (${this.getType(note.type)})</span> ${note.title}`;
        this.content = note.text;
        const mediaFetcher = new MediaFetcher(this.store);
        this.content = await mediaFetcher.insertMediaHtml(note.text);
    }

    getType(type) {
        return (new NoteTypeMatcher).getNoteType(this.registers, type)
    }

    getUniqueIdBetweenNotes() {
        return this.rubric+this.id;
    }
}