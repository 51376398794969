var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.$route.name != "bible" ||
    (_vm.isReRenderFooter && _vm.$route.name == "bible")
    ? _c("footer", [_vm._m(0)])
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "container" }, [
      _c("img", { attrs: { src: "/images/logo-footer-best.png", alt: "" } }),
      _vm._v(" "),
      _c("p", {}, [
        _vm._v("© École Biblique 2024\n      – "),
        _c(
          "a",
          {
            staticClass: "external",
            attrs: {
              href: "https://www.bibletraditions.org/conditions-generales-dutilisation/",
            },
          },
          [_vm._v("CGU")]
        ),
        _vm._v("\n      – "),
        _c(
          "a",
          {
            staticClass: "external",
            attrs: {
              href: "https://www.bibletraditions.org/mentions-legales/",
            },
          },
          [_vm._v("Mentions légales")]
        ),
        _vm._v("\n      – "),
        _c(
          "a",
          {
            staticClass: "external",
            attrs: {
              href: "https://www.bibletraditions.org/politique-de-confidentialite/",
            },
          },
          [_vm._v("Politique de confidentialité")]
        ),
        _vm._v("\n      – "),
        _c(
          "a",
          {
            staticClass: "external",
            attrs: {
              href: "https://www.bibletraditions.org/politique-cookies/",
            },
          },
          [_vm._v("Politique Cookies")]
        ),
        _vm._v("\n      – "),
        _c(
          "a",
          {
            staticClass: "external",
            attrs: { href: "https://www.bibletraditions.org/contact" },
          },
          [_vm._v("Contact")]
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }