import BestApiService from '../services/BestApiService.js';

/**
 * Loads media links in an HTML string or an Element node.
 */
export default class {

    /**
     * @param {Object} store A Vuex store used when fetching a media
     *     authorization token.
     */
    constructor(store) {
        this.store = store;
    }

    /**
     * Fetches media links and inserts them into an HTML string.
     *
     * @param {html} string
     * @returns {string}
     */
    async insertMediaHtml(html) {
        const domParser = new DOMParser();
        const el = domParser.parseFromString(html, 'text/html').body;
        const modified = await this.insertMediaElements(el);
        return modified ? el.outerHTML : html;
    }

    /**
     * Fetches media links and inserts them into an Element node.
     *
     * @param {Element} el
     * @returns {boolean} `true` if the element was modified, `false` if
     *     the element was not modified because there were no media links
     *     or if media links had already been inserted.
     */
    async insertMediaElements(el) {

        const figures = el.querySelectorAll('figure.media');

        if (figures.length == 0 ||
            (figures[0].firstChild?.nodeName != 'FIGCAPTION')) {

            // No media or media already loaded.
            return false;
        }

        const token = await BestApiService.fetchMediaAuthorizationToken(this.store);
        const baseUrl = this.store.getters.getMediaPlatformUrl();

        await Promise.all([...figures].map(async figure => {

            const path = figure.dataset.path;
            const url = `${baseUrl}/api/player/${path}?optimized=true`;

            const response = await fetch(url, { headers: { Authorization: `Bearer ${token}` } });
            const json = await response.json();
            const div = document.createElement('div');
            div.innerHTML = json.html;
            const mediaEl = div.firstChild;

            if (mediaEl.nodeName == 'IFRAME') {
                if (json.playerConfig.provider == 'youtube') {
                    figure.classList.add('iframe-media');
                    figure.classList.add('await');
                } else if (json.playerConfig?.file?.type == 'audio') {
                    figure.classList.add('iframe-audio');
                    figure.classList.add('await');
                }
            }

            figure.prepend(mediaEl);
        }));

        return true;
    }
}
