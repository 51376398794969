var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "tradition-configuration-wrapper" }, [
    _c("div", {}, [
      _c("div", { staticClass: "chapter-title" }, [
        _vm._v(_vm._s(_vm.$t("select_chapter")) + " :"),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "chapters" },
        _vm._l(_vm.chapters, function (chapter) {
          return _c(
            "router-link",
            {
              staticClass: "chapter",
              class: _vm.currentChapter === chapter ? "active" : "",
              attrs: {
                to: {
                  name: "bible",
                  params: {
                    bookAbbreviation: _vm.bookAbbreviation,
                    chapter: chapter,
                  },
                },
              },
            },
            [_vm._v(_vm._s(chapter) + "\n      ")]
          )
        }),
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }