var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.showModal
    ? _c("div", { staticClass: "greetings-modal" }, [
        _c("div", { staticClass: "greetings-modal-content" }, [
          _c("div", { staticClass: "text-right" }, [
            _c(
              "button",
              { staticClass: "x-button", on: { click: _vm.closeModal } },
              [
                _c(
                  "svg",
                  {
                    attrs: {
                      xmlns: "http://www.w3.org/2000/svg",
                      width: "28",
                      height: "28",
                      viewBox: "0 0 24 24",
                      "stroke-width": "1.5",
                      stroke: "#000000",
                      fill: "none",
                      "stroke-linecap": "round",
                      "stroke-linejoin": "round",
                    },
                  },
                  [
                    _c("path", {
                      attrs: {
                        stroke: "none",
                        d: "M0 0h24v24H0z",
                        fill: "none",
                      },
                    }),
                    _vm._v(" "),
                    _c("path", { attrs: { d: "M18 6l-12 12" } }),
                    _vm._v(" "),
                    _c("path", { attrs: { d: "M6 6l12 12" } }),
                  ]
                ),
              ]
            ),
          ]),
          _vm._v(" "),
          _c("img", {
            staticClass: "mb-6",
            attrs: { src: "/images/bibleart-logo.svg", alt: "" },
          }),
          _vm._v(" "),
          _c("h2", { staticClass: "text-center" }, [_vm._v("Bienvenue !")]),
          _vm._v(" "),
          _vm._m(0),
          _vm._v(" "),
          _vm._m(1),
          _vm._v(" "),
          _vm._m(2),
          _vm._v(" "),
          _vm._m(3),
          _vm._v(" "),
          _vm._m(4),
          _vm._v(" "),
          _c("div", { staticClass: "text-center" }, [
            _c(
              "button",
              {
                staticClass: "btn-red register-btn",
                on: { click: _vm.closeModal },
              },
              [_vm._v("Je crée mon profil")]
            ),
          ]),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("strong", [
        _vm._v(
          "Vous trouverez ici toutes les introductions, traductions et notes présentes sur notre application mobile, et même davantage :"
        ),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("strong", [_vm._v("– Des milliers de références")]),
      _vm._v(
        " dans la Bible, pour entendre tous les harmoniques et les jeux d’échos dans les Écritures"
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("strong", [
        _vm._v("– la possibilité de consulter plusieurs versions"),
      ]),
      _vm._v(
        " du même texte biblique, pour entendre la Parole de Dieu comme une polyphonie,"
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("strong", [
        _vm._v("– la possibilité de consulter des milliers de notes"),
      ]),
      _vm._v(" exégétiques dans 29 disciplines liées aux sciences bibliques."),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "text-center mb-2" }, [
      _c("strong", [_vm._v("Pour profiter de tout cela :")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }