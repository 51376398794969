var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "verse",
      class: _vm
        .verseStatus(_vm.isActiveNotesVerse, _vm.traductionConfiguration)
        .concat([_vm.highlighted && "verse-note-highlighted"]),
    },
    [
      _c(
        "div",
        { staticClass: "traditions d-flex justify-content-end pr-5 pt-1" },
        _vm._l(
          _vm.versesByTradition.mainVerseTradition.traditions,
          function (tradition) {
            return _c(
              "span",
              {
                staticClass: "tradition-badge badge-color-hover mr-1 mb-1",
                class: _vm.mainTradition === tradition ? "active" : "inactive",
                style:
                  "--color-badge:" + _vm.getTraditionObject(tradition).color,
                attrs: {
                  title: _vm.getTraditionObject(tradition).name[_vm.locale],
                },
              },
              [_vm._v(_vm._s(tradition))]
            )
          }
        ),
        0
      ),
      _vm._v(" "),
      _c("div", { staticClass: "verse_metadata" }, [
        _c("span", { staticClass: "verse_number" }, [
          _vm._v(_vm._s(_vm.verseNumber)),
        ]),
      ]),
      _vm._v(" "),
      _vm.versesByTradition
        ? _c("div", { staticClass: "verse_content" }, [
            _c("div", {
              domProps: {
                innerHTML: _vm._s(
                  _vm.versesByTradition.mainVerseTradition.verse
                ),
              },
            }),
            _vm._v(" "),
            _vm.versesByTradition.otherVerseTradition.length
              ? _c("div", { staticClass: "other-traditions" }, [
                  _c(
                    "div",
                    { staticClass: "other-traditions-content" },
                    _vm._l(
                      _vm.versesByTradition.otherVerseTradition,
                      function (otherTraditionVerse) {
                        return _c("div", { staticClass: "tradition-wrapper" }, [
                          _c(
                            "div",
                            { staticClass: "traditions" },
                            _vm._l(
                              otherTraditionVerse.traditions,
                              function (tradition) {
                                return _c(
                                  "span",
                                  {
                                    staticClass:
                                      "tradition-badge badge-color-hover mr-1",
                                    class:
                                      _vm.mainTradition === tradition
                                        ? "active"
                                        : "inactive",
                                    style:
                                      "--color-badge:" +
                                      _vm.getTraditionObject(tradition).color,
                                    attrs: {
                                      title:
                                        _vm.getTraditionObject(tradition).name[
                                          _vm.locale
                                        ],
                                    },
                                  },
                                  [_vm._v(_vm._s(tradition))]
                                )
                              }
                            ),
                            0
                          ),
                          _vm._v(" "),
                          _c("div", {
                            domProps: {
                              innerHTML: _vm._s(otherTraditionVerse.verse),
                            },
                          }),
                        ])
                      }
                    ),
                    0
                  ),
                ])
              : _vm._e(),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }