var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "tradition-configuration-wrapper traditions" },
    [
      !_vm.token
        ? _c("div", { staticClass: "no-access-traditions" }, [
            _c("p", {
              domProps: { innerHTML: _vm._s(_vm.$t("inaccessible_search")) },
            }),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "tradition-configuration-content",
          class: _vm.token ? "" : "no-access",
        },
        [
          _vm.error
            ? _c("p", { staticClass: "error" }, [_vm._v(_vm._s(_vm.error))])
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            [
              _c("p", { staticClass: "mb-0" }, [
                _c("strong", [_vm._v(_vm._s(_vm.$t("active_traditions")))]),
              ]),
              _vm._v(" "),
              _vm._l(_vm.availableTraditions, function (traditionAbbreviation) {
                return _c(
                  "div",
                  {
                    staticClass: "switcher",
                    attrs: {
                      title: _vm.getTraditionObject(traditionAbbreviation)
                        .isDefault
                        ? _vm.$t("impossible_remove_tradition")
                        : false,
                    },
                  },
                  [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.checkedTraditions,
                          expression: "checkedTraditions",
                        },
                      ],
                      attrs: {
                        type: "checkbox",
                        id: "tradition_" + traditionAbbreviation,
                        disabled: _vm.getTraditionObject(traditionAbbreviation)
                          .isDefault,
                      },
                      domProps: {
                        value: traditionAbbreviation,
                        checked: Array.isArray(_vm.checkedTraditions)
                          ? _vm._i(
                              _vm.checkedTraditions,
                              traditionAbbreviation
                            ) > -1
                          : _vm.checkedTraditions,
                      },
                      on: {
                        change: [
                          function ($event) {
                            var $$a = _vm.checkedTraditions,
                              $$el = $event.target,
                              $$c = $$el.checked ? true : false
                            if (Array.isArray($$a)) {
                              var $$v = traditionAbbreviation,
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 &&
                                  (_vm.checkedTraditions = $$a.concat([$$v]))
                              } else {
                                $$i > -1 &&
                                  (_vm.checkedTraditions = $$a
                                    .slice(0, $$i)
                                    .concat($$a.slice($$i + 1)))
                              }
                            } else {
                              _vm.checkedTraditions = $$c
                            }
                          },
                          function ($event) {
                            return _vm.updateActiveTradition()
                          },
                        ],
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "label",
                      { attrs: { for: "tradition_" + traditionAbbreviation } },
                      [
                        _c("div", [
                          _c(
                            "span",
                            {
                              staticClass: "tradition-abbreviation",
                              style:
                                "--color-badge:" +
                                _vm.getTraditionObject(traditionAbbreviation)
                                  .color,
                            },
                            [_vm._v(_vm._s(traditionAbbreviation))]
                          ),
                          _vm._v(" "),
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.getTraditionObject(traditionAbbreviation)
                                  .labelPanelConfiguration[_vm.locale]
                              )
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("span", {
                          staticClass: "slider",
                          class: _vm.getTraditionObject(traditionAbbreviation)
                            .isDefault
                            ? "disabled"
                            : "",
                        }),
                      ]
                    ),
                  ]
                )
              }),
            ],
            2
          ),
          _vm._v(" "),
          _c("div", [
            _c("p", { staticClass: "mb-2 mb-md-7" }, [
              _c("strong", [_vm._v(_vm._s(_vm.$t("tradition_display_rule")))]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "custom-radio" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.checkedTraductionConfiguration,
                    expression: "checkedTraductionConfiguration",
                  },
                ],
                attrs: {
                  type: "radio",
                  id: "traduction_configuration_open",
                  name: "traduction_configuration",
                  value: "open",
                },
                domProps: {
                  checked: _vm._q(_vm.checkedTraductionConfiguration, "open"),
                },
                on: {
                  change: [
                    function ($event) {
                      _vm.checkedTraductionConfiguration = "open"
                    },
                    function ($event) {
                      return _vm.updateTraductionConfiguration()
                    },
                  ],
                },
              }),
              _vm._v(" "),
              _c("label", { attrs: { for: "traduction_configuration_open" } }, [
                _c("span", { staticClass: "radio-toggle" }),
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.$t("tradition_display_show")) +
                    "\n        "
                ),
              ]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.checkedTraductionConfiguration,
                    expression: "checkedTraductionConfiguration",
                  },
                ],
                attrs: {
                  type: "radio",
                  id: "traduction_configuration_close",
                  name: "traduction_configuration",
                  value: "close",
                },
                domProps: {
                  checked: _vm._q(_vm.checkedTraductionConfiguration, "close"),
                },
                on: {
                  change: [
                    function ($event) {
                      _vm.checkedTraductionConfiguration = "close"
                    },
                    function ($event) {
                      return _vm.updateTraductionConfiguration()
                    },
                  ],
                },
              }),
              _vm._v(" "),
              _c(
                "label",
                { attrs: { for: "traduction_configuration_close" } },
                [
                  _c("span", { staticClass: "radio-toggle" }),
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t("tradition_display_hide")) +
                      "\n        "
                  ),
                ]
              ),
            ]),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }