var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "panel-right notes-panel", class: _vm.isOpen ? "open" : "" },
    [
      _c("div", { staticClass: "panel-heading" }, [
        _vm._v("\n    Notes\n    "),
        _c("div", {
          staticClass: "close-panel",
          on: {
            click: function ($event) {
              return _vm.closePanel()
            },
          },
        }),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "panel-wrapper" }, [
        _vm.isLoading
          ? _c("div", [
              _vm._v("\n      " + _vm._s(_vm.$t("loading")) + "\n    "),
            ])
          : _c("div", { staticClass: "panel-text-wrapper" }, [
              _c("ul", { staticClass: "panel-nav" }, [
                _c(
                  "li",
                  {
                    class: _vm.currentPanel === "note" ? "active" : "",
                    on: {
                      click: function ($event) {
                        return _vm.changeCurrentPanel("note")
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.$t("comments")))]
                ),
                _vm._v(" "),
                _c(
                  "li",
                  {
                    class: _vm.currentPanel === "synthese" ? "active" : "",
                    on: {
                      click: function ($event) {
                        return _vm.changeCurrentPanel("synthese")
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.$t("synthesis")))]
                ),
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.currentPanel === "note",
                      expression: "currentPanel === 'note'",
                    },
                  ],
                  staticClass: "note-content",
                },
                [
                  _vm.notes.length
                    ? _c(
                        "div",
                        _vm._l(_vm.registers, function (rubrics, registerName) {
                          return _vm.getNotesByRegister(rubrics).length
                            ? _c(
                                "div",
                                [
                                  _c(
                                    "p",
                                    {
                                      staticClass: "notes-title",
                                      style:
                                        "color:" +
                                        _vm.getFullNameRegister(registerName)
                                          .color,
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.getFullNameRegister(registerName)
                                            .label[_vm.locale]
                                        )
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _vm._l(
                                    rubrics,
                                    function (rubricName, rubricAbbr) {
                                      return _vm.getNotesByRubric(rubricAbbr)
                                        .length
                                        ? _c(
                                            "div",
                                            [
                                              _c(
                                                "p",
                                                {
                                                  staticClass: "rubrique-name",
                                                  style:
                                                    "--color-register:" +
                                                    _vm.getFullNameRegister(
                                                      registerName
                                                    ).color,
                                                },
                                                [_vm._v(_vm._s(rubricName))]
                                              ),
                                              _vm._v(" "),
                                              _vm._l(
                                                _vm.getNotesByRubric(
                                                  rubricAbbr
                                                ),
                                                function (note) {
                                                  return _c(
                                                    "div",
                                                    {
                                                      ref: "note_" + note.id,
                                                      refInFor: true,
                                                      staticClass:
                                                        "note-content panel-collapse",
                                                    },
                                                    [
                                                      _c(
                                                        "p",
                                                        {
                                                          staticClass:
                                                            "panel-collapse-title note-title",
                                                          class:
                                                            _vm.openNote ===
                                                            note.id
                                                              ? "collapse-open"
                                                              : "",
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.changeOpenNote(
                                                                $event,
                                                                _vm.openNote ===
                                                                  note.id
                                                                  ? null
                                                                  : note.id
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "chevron-icon",
                                                            },
                                                            [
                                                              _c(
                                                                "svg",
                                                                {
                                                                  staticClass:
                                                                    "chevron-right",
                                                                  attrs: {
                                                                    xmlns:
                                                                      "http://www.w3.org/2000/svg",
                                                                    width: "20",
                                                                    height:
                                                                      "20",
                                                                    viewBox:
                                                                      "0 0 24 24",
                                                                    "stroke-width":
                                                                      "3",
                                                                    stroke:
                                                                      "#1B2847",
                                                                    fill: "none",
                                                                    "stroke-linecap":
                                                                      "round",
                                                                    "stroke-linejoin":
                                                                      "round",
                                                                  },
                                                                },
                                                                [
                                                                  _c("path", {
                                                                    attrs: {
                                                                      stroke:
                                                                        "none",
                                                                      d: "M0 0h24v24H0z",
                                                                      fill: "none",
                                                                    },
                                                                  }),
                                                                  _vm._v(" "),
                                                                  _c("path", {
                                                                    attrs: {
                                                                      d: "M9 6l6 6l-6 6",
                                                                    },
                                                                  }),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                          _vm._v(" "),
                                                          _c("span", {
                                                            staticClass:
                                                              "note-title-text",
                                                            domProps: {
                                                              innerHTML: _vm._s(
                                                                note.title
                                                              ),
                                                            },
                                                          }),
                                                          _vm._v(
                                                            " (" +
                                                              _vm._s(
                                                                note.scope
                                                                  .relative
                                                              ) +
                                                              ")\n                  "
                                                          ),
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "copy-icon",
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    $event.stopPropagation()
                                                                    return _vm.copyNoteLink(
                                                                      note.id
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "svg",
                                                                {
                                                                  staticClass:
                                                                    "link-icon",
                                                                  attrs: {
                                                                    xmlns:
                                                                      "http://www.w3.org/2000/svg",
                                                                    viewBox:
                                                                      "0 0 24 24",
                                                                    "stroke-width":
                                                                      "2",
                                                                    fill: "none",
                                                                    "stroke-linecap":
                                                                      "round",
                                                                    "stroke-linejoin":
                                                                      "round",
                                                                    title:
                                                                      "Copier le lien",
                                                                  },
                                                                },
                                                                [
                                                                  _c("path", {
                                                                    attrs: {
                                                                      stroke:
                                                                        "none",
                                                                      d: "M0 0h24v24H0z",
                                                                      fill: "none",
                                                                    },
                                                                  }),
                                                                  _vm._v(" "),
                                                                  _c("path", {
                                                                    attrs: {
                                                                      d: "M10 14a3.5 3.5 0 0 1 0 -5l4 -4a3.5 3.5 0 0 1 5 5l-1.5 1.5",
                                                                    },
                                                                  }),
                                                                  _vm._v(" "),
                                                                  _c("path", {
                                                                    attrs: {
                                                                      d: "M14 10a3.5 3.5 0 0 1 0 5l-4 4a3.5 3.5 0 0 1 -5 -5l1.5 -1.5",
                                                                    },
                                                                  }),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "div",
                                                        {
                                                          directives: [
                                                            {
                                                              name: "show",
                                                              rawName: "v-show",
                                                              value:
                                                                _vm.openNote ===
                                                                note.id,
                                                              expression:
                                                                "openNote === note.id",
                                                            },
                                                          ],
                                                        },
                                                        [
                                                          _c(
                                                            "p",
                                                            {
                                                              staticStyle: {
                                                                color:
                                                                  "#b40000",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  note.lemma
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                          _vm._v(" "),
                                                          _c("div", {
                                                            staticClass:
                                                              "note-paragraphs",
                                                            domProps: {
                                                              innerHTML: _vm._s(
                                                                note.text
                                                              ),
                                                            },
                                                          }),
                                                        ]
                                                      ),
                                                    ]
                                                  )
                                                }
                                              ),
                                            ],
                                            2
                                          )
                                        : _vm._e()
                                    }
                                  ),
                                ],
                                2
                              )
                            : _vm._e()
                        }),
                        0
                      )
                    : _vm.activeVerse === null
                    ? _c("p", [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$t("no_note_selected")) +
                            "\n        "
                        ),
                      ])
                    : _c("div", [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$t("no_notes")) +
                            "\n        "
                        ),
                      ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.currentPanel === "synthese",
                      expression: "currentPanel === 'synthese'",
                    },
                  ],
                },
                [
                  _c(
                    "div",
                    { staticClass: "alphabet-letter-nav" },
                    _vm._l(
                      _vm.notesByLetter,
                      function (synthNotesByLetter, letter) {
                        return _c("div", { key: "alphabet-letter-" + letter }, [
                          _c(
                            "span",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.scrollToLetter(letter)
                                },
                              },
                            },
                            [_vm._v(_vm._s(letter))]
                          ),
                        ])
                      }
                    ),
                    0
                  ),
                  _vm._v(" "),
                  _vm._l(
                    _vm.notesByLetter,
                    function (synthNotesByLetter, letter) {
                      return _c(
                        "div",
                        {
                          key: letter,
                          ref: "notes-in-letter-" + letter,
                          refInFor: true,
                        },
                        [
                          _c("p", { staticClass: "alphabet-letter" }, [
                            _vm._v(_vm._s(letter)),
                          ]),
                          _vm._v(" "),
                          _vm._l(synthNotesByLetter, function (note) {
                            return _c(
                              "div",
                              {
                                ref: "synth_note_" + note.id,
                                refInFor: true,
                                staticClass: "note-content panel-collapse",
                              },
                              [
                                _c(
                                  "p",
                                  {
                                    staticClass:
                                      "panel-collapse-title note-title",
                                    class:
                                      _vm.openNote === note.id
                                        ? "collapse-open"
                                        : "",
                                    on: {
                                      click: function ($event) {
                                        return _vm.changeOpenNote(
                                          $event,
                                          _vm.openNote === note.id
                                            ? null
                                            : note.id
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "svg",
                                      {
                                        staticClass: "chevron-right",
                                        attrs: {
                                          xmlns: "http://www.w3.org/2000/svg",
                                          width: "20",
                                          height: "20",
                                          viewBox: "0 0 24 24",
                                          "stroke-width": "3",
                                          stroke: "#1B2847",
                                          fill: "none",
                                          "stroke-linecap": "round",
                                          "stroke-linejoin": "round",
                                        },
                                      },
                                      [
                                        _c("path", {
                                          attrs: {
                                            stroke: "none",
                                            d: "M0 0h24v24H0z",
                                            fill: "none",
                                          },
                                        }),
                                        _c("path", {
                                          attrs: { d: "M9 6l6 6l-6 6" },
                                        }),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c("span", {
                                      domProps: {
                                        innerHTML: _vm._s(note.title),
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      {
                                        staticClass: "copy-icon",
                                        on: {
                                          click: function ($event) {
                                            $event.stopPropagation()
                                            return _vm.copyNoteLink(note.id)
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "svg",
                                          {
                                            staticClass: "link-icon",
                                            attrs: {
                                              xmlns:
                                                "http://www.w3.org/2000/svg",
                                              viewBox: "0 0 24 24",
                                              "stroke-width": "2",
                                              fill: "none",
                                              "stroke-linecap": "round",
                                              "stroke-linejoin": "round",
                                              title: "Copier le lien",
                                            },
                                          },
                                          [
                                            _c("path", {
                                              attrs: {
                                                stroke: "none",
                                                d: "M0 0h24v24H0z",
                                                fill: "none",
                                              },
                                            }),
                                            _vm._v(" "),
                                            _c("path", {
                                              attrs: {
                                                d: "M10 14a3.5 3.5 0 0 1 0 -5l4 -4a3.5 3.5 0 0 1 5 5l-1.5 1.5",
                                              },
                                            }),
                                            _vm._v(" "),
                                            _c("path", {
                                              attrs: {
                                                d: "M14 10a3.5 3.5 0 0 1 0 5l-4 4a3.5 3.5 0 0 1 -5 -5l1.5 -1.5",
                                              },
                                            }),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.openNote === note.id,
                                        expression: "openNote === note.id",
                                      },
                                    ],
                                    staticClass: "note-paragraphs",
                                  },
                                  [
                                    _c("div", {
                                      domProps: {
                                        innerHTML: _vm._s(note.text),
                                      },
                                    }),
                                  ]
                                ),
                              ]
                            )
                          }),
                        ],
                        2
                      )
                    }
                  ),
                ],
                2
              ),
            ]),
      ]),
      _vm._v(" "),
      _vm.margsRefs.length
        ? _c(
            "div",
            { staticClass: "bottom-sticky-collapse" },
            _vm._l(_vm.margsRefs, function (margRef) {
              return _c("div", [
                _c("p", { domProps: { innerHTML: _vm._s(margRef.text) } }),
              ])
            }),
            0
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }