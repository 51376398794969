// for IE
import 'whatwg-fetch';

// libs
import Vue from 'vue';
import VueI18n from 'vue-i18n';
import messages from './translations';
import router from './router';
import store from './store';
import Routing from '../../vendor/friendsofsymfony/jsrouting-bundle/Resources/public/js/router.min.js';
import vuetify from './plugins/vuetify';

// my components
import Mobile from './Mobile.vue';

const routes = require('./fos_js_routes.json');
const $ = require('jquery');
global.$ = global.jQuery = $;
window.$ = window.jQuery = $;

// JS
require('bootstrap');
require('downloadjs');

// CSS
require('shepherd.js/dist/css/shepherd.css');
require('bootstrap/dist/css/bootstrap.css');
require('bootstrap-vue/dist/bootstrap-vue.css');
require('../css/mobile.scss');

window.$vueEventBus = Vue.prototype.$vueEventBus = new Vue();
Routing.setRoutingData(routes);

/**
 * Tools for JWT
 */
window.parseJwt = (token) => {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));
  return JSON.parse(jsonPayload);
};

/**
 * Instanciate VueJS app
 */
Vue.use(VueI18n);

// NB: we would like to do this in the $store, but this.$i18n cannot be reached from there
var locale = localStorage.getItem('locale');
if(!locale) {
    locale = 'fr'
    localStorage.setItem('locale', locale);
}

const i18n = new VueI18n({
  locale,
  fallbackLocale: 'fr',
  messages,
});

$(document).ready(function () {
    new Vue({
        created() {
            this.$store.dispatch('loadFromLocalStorage');
            this.$store.commit('SET_BIBLE_API_URL', $('#mobile-app').attr('bible_api_url'));
            this.$store.commit('SET_MEDIA_PLATFORM_URL', $('#mobile-app').attr('media_platform_url'));
            this.$store.commit('SET_RECAPTCHA_SITE_KEY', $('#mobile-app').attr('recaptcha_site_key'));
        },
        render: h => h(Mobile),
        el: '#mobile-app',
        router,
        store,
        vuetify,
        i18n,
    });

    // Gestion de la fermeture de la recherche
    $(document).on('click', '.customModalRecherche', function(e) {
        let target = e.target
        if($(target).hasClass('customModalRecherche')) {
            window.$vueEventBus.$emit('closeResearch');
        }
    });

    // Fermer les panel de traditions
    $(document).on('click', '.bible-content-wrapper', function(e) {
        let target = e.target;
        if($(target).hasClass('bible-content-wrapper')) {
            if($(this).hasClass('trad-panel-open') && $('.traditions-toggle').hasClass('active')) {
                $('.traditions-toggle').click();
            }
            if($(this).hasClass('chapter-panel-open')) {
                $('.chapter-number-toggle').click();
            }
        }
    });

    // Fermer les modals de connexion,inscription et donation
    $(document).on('click', '.v-menu__content', function(e) {
        let target = e.target;
        if($(target).hasClass('v-menu__content')) {
            if($('.login-btn').hasClass('active')) {
                $('.login-btn').click().blur();
            } if($('.donation-btn').hasClass('active')) {
                $('.donation-btn').click().blur();
            } else if($('.register-btn').hasClass('active')) {
                $('.register-btn').click().blur();
            }
        }
    });

    $(document).on('click', '.greetings-modal-content .register-btn', function(e) {
        $('.v-app-bar .register-btn').click();
    });

    $(document).on('click', '.register-btn, .login-btn, .donation-btn', function() {
        if($(this).hasClass('login-btn')) {
            $('.register-btn').removeClass('active');
            $('.donation-btn').removeClass('active');
        } if($(this).hasClass('donation-btn')) {
            $('.register-btn').removeClass('active');
            $('.login-btn').removeClass('active');
        } else {
            $('.login-btn').removeClass('active');
            $('.donation-btn').removeClass('active');
        }
        $(this).toggleClass('active')

        // Fermer la recherche
        $('.customModalRecherche').hide()
        $('.v-toolbar .search .close-search').hide();
        $('.v-toolbar .search input').removeClass('focused');
    });

    // Au changement de chapitre, scroll en haut
    $('.top-navigation .arrow-left, .top-navigation .arrow-right').on('click', function() {
        $('.content-bible').scrollTop(0)
    });

    // Choix du montant de donation à l'inscription
    $(document).on('change', '.registration-form-wrapper .form-bottom .radio-wrapper input', function() {
        let id = $(this).attr('id');
        if(id == 'donnation-other') {
            $('.registration-form-wrapper #other-amount').show('120');
        } else {
            $('.registration-form-wrapper #other-amount').hide('120');
        }
    });

    $(document).on('click', '.open-registration-modal', function() {
        $('.register-btn').click();
    });

    $(document).on('click', '.close-registration-modal', function() {
        $('.register-btn').click().removeClass('active');
    });

    $(document).on('click', '.close-login-modal', function() {
        $('.login-btn').click().removeClass('active');
    });

    $(document).on('click', '.open-donation-modal', function() {
        $('.donation-btn').click();
    });

    $(document).on('click', '.close-donation-modal', function() {
        $('.donation-btn').click().removeClass('active');
    });

    // Lightbox des images
    $(document).on('click', '.panel-right img', function () {
        let src = $(this).attr('src');
        let alt = $(this).attr('alt');
        let caption = $(this).parent('figure').find('figcaption').text();

        // Create Lightbox
        $('body').append('' +
            '<div class="lightbox-wrapper">' +
                '<div class="close-lightbox"><svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-x" width="32" height="32" viewBox="0 0 24 24" stroke-width="1.5" stroke="#ffffff" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M18 6l-12 12" /><path d="M6 6l12 12" /></svg></div>' +
                '<div class="lightbox-content">' +
                    '<img class="lightbox-content-item" src="'+src+'" alt="'+alt+'">' +
                    '<p class="lightbox-content-item alt">'+alt+'</p>' +
                '</p>' +
            '</div>');
    });

    // Fermer la Lightbox des images
    $(document).on('click', '.lightbox-wrapper', function (e) {
        let target = e.target

        if(!$(target).hasClass('lightbox-content-item') || $(target).hasClass('close-lightbox')) {
           $(this).remove();
        }
    });

    // Fermer le panel des chapitres au clic
    $(document).on('click', '.tradition-configuration-wrapper .chapters a', function (e) {
        $('.bible-content-wrapper').removeClass('chapter-panel-open');
    });

    // Gestion du menu mobile
    $(document).on('click', '.open-mobile-menu', function (e) {
        $(this).siblings('.custom-mobile-menu').toggleClass('show')
    });


});

/**
 * PWA
 */
if ('serviceWorker' in navigator) {
  navigator.serviceWorker.register('/sw.js', { scope: '/' }).then(function(reg) {
    console.log('Registration succeeded. Scope is ' + reg.scope);
  }).catch(function(error) {
    console.log('Registration failed with ' + error);
  });
};
