export default class CookieManager {
    getCookie(key, default_) {
        var keyValue = document.cookie.match('(^|;) ?' + key + '=([^;]*)(;|$)');
        if (keyValue) {
            return (keyValue[2] == 'false' ? false : keyValue[2]);
        }
        return default_;
    }

    setCookie(key, value) {
        var expires = new Date();
        expires.setTime(expires.getTime() + (10 * 365 * 24 * 60 * 60 * 1000)); // 10 years in milliseconds
        document.cookie = key + '=' + value + ';expires=' + expires.toUTCString()+';path=/';
    }
}