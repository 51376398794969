import { render, staticRenderFns } from "./Navigation.vue?vue&type=template&id=71d1d8f5&scoped=true&"
import script from "./Navigation.vue?vue&type=script&lang=js&"
export * from "./Navigation.vue?vue&type=script&lang=js&"
import style0 from "./Navigation.vue?vue&type=style&index=0&lang=css&"
import style1 from "./Navigation.vue?vue&type=style&index=1&id=71d1d8f5&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "71d1d8f5",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/juhum/Code/spyrit/best_site/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('71d1d8f5')) {
      api.createRecord('71d1d8f5', component.options)
    } else {
      api.reload('71d1d8f5', component.options)
    }
    module.hot.accept("./Navigation.vue?vue&type=template&id=71d1d8f5&scoped=true&", function () {
      api.rerender('71d1d8f5', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "assets/js/components/Navigation.vue"
export default component.exports