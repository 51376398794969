var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.note
    ? _c(
        "div",
        { staticClass: "note" },
        [
          _c("v-expansion-panel-header", {
            domProps: { innerHTML: _vm._s(_vm.title) },
          }),
          _vm._v(" "),
          _c("v-expansion-panel-content", { staticClass: "note-content" }, [
            _c("div", {
              domProps: { innerHTML: _vm._s(_vm.text) },
              on: {
                click: function ($event) {
                  return _vm.handleClick($event)
                },
              },
            }),
          ]),
          _vm._v(" "),
          _c(
            "v-dialog",
            {
              attrs: { "max-width": "100%", "max-height": "100%" },
              model: {
                value: _vm.dialog,
                callback: function ($$v) {
                  _vm.dialog = $$v
                },
                expression: "dialog",
              },
            },
            [
              _c(
                "div",
                { staticStyle: { "text-align": "center", background: "#000" } },
                [
                  _c("img", {
                    staticStyle: { width: "auto", "max-width": "100%" },
                    attrs: { src: _vm.activeImageUrl, alt: "image" },
                  }),
                ]
              ),
            ]
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }