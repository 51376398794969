import ModalContentClass from "./ModalContentClass";
import BestApiService from "../services/BestApiService";

export default class BibRefModalContentClass extends ModalContentClass {
    constructor(id, store) {
        super(id, store);
        this.initContent();
    }

    initContent() {
        BestApiService.fetchBibliographicReferences(this.store, this.id.replace('*', ''))
            .catch(e => {
                this.title = 'unknown_modal_title';
                this.content = 'unknown_modal_content';
            })
            .then(response => {
                if(response.status === 403){
                    if(!this.store.getters.isPaying()) {
                        this.title = 'premium_modal_title';
                        this.content = 'premium_modal_content';
                    }
                }
                return response.json();
            }).then(responseData => {
                if (responseData === null || typeof responseData === 'undefined') {
                    throw new Error('no-op');
                }

                const bibRef = responseData.filter(ref => ref.id === this.id)[0];
                if(bibRef) {
                    this.title = bibRef.title;
                    this.content = this.formatContent(bibRef.text);
                } else {
                    this.title = 'unknown_modal_title';
                    this.content = 'unknown_modal_content';
                }
        });
    }

    formatContent(html) {
        // Add line breaks after years but not if <a> just after
        html = html.replace(/(\b\d{4}\.)(?!\s*<a)/g, '$1<br>')

        // add line break after <a> tags
        html = html.replace(/(<a.*?>.*?<\/a>)/g, '$1<br>');

        return '<p>'+html+'</p>';
    }
}