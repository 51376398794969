import ModalContentClass from "./ModalContentClass";
import BestApiService from "../services/BestApiService";
import MediaFetcher from "./MediaFetcher";

export default class SynthModalContentClass extends ModalContentClass {
    constructor(id, store, fullSynthNote) {
        super(id, store);

        if(fullSynthNote) {
            this.title = fullSynthNote.title;
            this.content = fullSynthNote.text;
        }

        this.initContent();
    }

    async initContent() {
        if(this.title && this.content) {
            const mediaFetcher = new MediaFetcher(this.store);
            this.content = await mediaFetcher.insertMediaHtml(this.content);
            return;
        }

        if(this.getNote()) {
            this.title = this.getNote().title;
            const mediaFetcher = new MediaFetcher(this.store);
            this.content = await mediaFetcher.insertMediaHtml(this.getNote().text);
        } else if(!this.store.getters.isPaying()) {
            this.title = 'premium_modal_title';
            this.content = 'premium_modal_content';
        } else {
            this.title = 'unknown_modal_title';
            this.content = 'unknown_modal_content';
        }
    }

    getNote() {
        BestApiService.getSynthNotes(this.store, false, () => {
            this.synthNotes = this.store.getters.getSynthNotes();
        });

        const synthNote =  this.store.getters.getSynthNote(this.id);
        if(synthNote) {
            return synthNote;
        }
    }
}