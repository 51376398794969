import Vue from 'vue';
import Router from 'vue-router';
import Home from './views/Home.vue';
import Bible from './views/Bible.vue';
import SommaireBible from "./views/SommaireBible.vue";

Vue.use(Router);

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home,
    },
    {
      path: '/bible',
      redirect: '/bible/Gn/1',
    },
    {
      path: '/bible/:bookAbbreviation',
      name: 'book',
      component: SommaireBible,
    },
    {
      path: '/bible/:bookAbbreviation/:chapter?',
      name: 'bible',
      component: Bible,
    },
  ],
    scrollBehavior (to, from, savedPosition) {
        return { x: 0, y: 0 };
    }
})

export default router
